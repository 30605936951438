$custom-font-path: "../fonts" !default;

@font-face {
	font-family: 'Open Sans';
	src: url('#{$custom-font-path}/OpenSans-Regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('#{$custom-font-path}/OpenSans-Bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('#{$custom-font-path}/OpenSans-Italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Open Sans';
	src: url('#{$custom-font-path}/OpenSans-BoldItalic-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'PT Sans';
	src: url('#{$custom-font-path}/PTSans-Regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PT Sans';
	src: url('#{$custom-font-path}/PTSans-Bold-webfont.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'PT Sans';
	src: url('#{$custom-font-path}/PTSans-Italic-webfont.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'PT Sans';
	src: url('#{$custom-font-path}/PTSans-BoldItalic-webfont.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}
