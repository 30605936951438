/**
 * This file is loaded after Bootstrap variables are defined.
 *
 * Bootstrap variables can be referred in this file for extra style definitions, but
 * if you want to override Bootstrap styles, use `_bootstrapOverride.scss` insted.
 */
@import 'mixins';

// Compact medium size button: otherwise identical to normal button, but more compact vertically
.btn.btn-compact:not(.btn-link) {
  @include button-size($padding-small-vertical, $padding-base-horizontal, $font-size-base, $line-height-large, $btn-border-radius-base);
}
