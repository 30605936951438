$tulli-black-color: #000000;
$tulli-blue-color: #00205b;
$tulli-top-bar-color: #4a4b50;
$tulli-dark-gray-color: #6b6b6c;
$tulli-mid-gray-color: #bbbcbc;
$tulli-light-gray-color: #fafafa;
$tulli-light-bluegray-color: #e3e5e9;
$tulli-bg-color: #eeeff2; //#E7EAF0;
$tulli-header-bg-color: #e3e5e9; //#C6CDDC;
$tulli-orange-color: #f26600;
$tulli-table-border-color: #bfcadb;
$tulli-list-odd-color: $tulli-light-gray-color;
$tulli-list-even-color: $tulli-bg-color;
$tulli-list-selected-color: #a7b3ca;
$tulli-list-hover-color: $tulli-list-selected-color;
$tulli-list-active-color: $tulli-table-border-color;
$tulli-white-color: #ffffff;
$tulli-disabled-color: rgba(0, 0, 0, 0.6);
$tulli-disabled-bg-color: #f5f5f5;

$tulli-yellow: #f2a900;

$tulli-success-color: #007b98;
$tulli-info-color: #6183c2;
$tulli-error-color: #c71530;
$tulli-error-bg-color: #fce8eb;
$tulli-btn-warning-color: #fdb700;
$tulli-warning-color: #936a00;
$tulli-warning-bg-color: #fef6e6;

$tulli-blue-hover-color: #19366b;
$tulli-light-hover-color: #e6e6e6;

$tulli-input-focus-color: $tulli-blue-color;
$tulli-disabled-btn-color: #bfcadb;
$tulli-inactive-tab-color: $tulli-disabled-btn-color;
$tulli-hover-tab-color: $tulli-inactive-tab-color;
$tulli-accordion-border-color: #8393af;
$tulli-accordion-background-color: #b2bccd;
$tulli-accordion-hover-background-color: #e5e8ee;
$tulli-login-info-box-background-color: #d9dfec;
$tulli-accordion-grey-border-color: #f1f2f4;
$tulli-input-placeholder: #767676;
$tulli-input-placeholder-error: #786669;
$tulli-input-placeholder-warning: #78715e;

$tulli-btn-focus-shadow: 0 0 10px 2px $tulli-blue-color !important;
$tulli-btn-focus-alt-shadow: 0 0 10px 2px $tulli-white-color !important;
$tulli-field-focus-shadow: 0 0 6px 0 $tulli-blue-color !important;

// Service specific colors
$tulli-passitus-color: #34b09c;
$tulli-tullivarasto-color: #6183c2;
$tulli-intrastat-blue: #64738f;
$tulli-aland: #b09ea1;
$tulli-tullaus: #f2a900;

$tulli-unit: 10px;
$tulli-gutter-width: $tulli-unit * 2;

$tulli-base-font-size: 14px;
$tulli-h1-font-size: 3.6rem;
$tulli-h2-font-size: 2.4rem;
$tulli-h3-font-size: 2rem;
$tulli-h4-font-size: 1.8rem;
$tulli-h5-font-size: 1.5rem;
$tulli-h6-font-size: 1.2rem;
$tulli-font-family-sans-serif: Open Sans, Arial, sans-serif;
$headings-font-weight: bold;

// Tulli icon font characters
$icon-tulli-add: "\e90c";
$icon-tulli-add-bold: "\e91e";
$icon-tulli-arrow-triangle-down: "\e910";
$icon-tulli-arrow-triangle-left: "\e911";
$icon-tulli-arrow-triangle-right: "\e912";
$icon-tulli-arrow-triangle-up: "\e913";
$icon-tulli-arrow-triangle-wide-down: "\e921";
$icon-tulli-arrow-triangle-wide-left: "\e914";
$icon-tulli-arrow-triangle-wide-right: "\e92e";
$icon-tulli-arrow-triangle-wide-up: "\e92f";
$icon-tulli-attention: "\e918";
$icon-tulli-calendar: "\e902";
$icon-tulli-checkbox-checked: "\e90d";
$icon-tulli-checkbox-unchecked: "\e90e";
$icon-tulli-chevron-down: "\e930";
$icon-tulli-chevron-left: "\e90f";
$icon-tulli-chevron-right: "\e931";
$icon-tulli-chevron-up: "\e932";
$icon-tulli-chevron-x-hard-down: "\e933";
$icon-tulli-chevron-x-hard-left: "\e920";
$icon-tulli-chevron-x-hard-right: "\e934";
$icon-tulli-chevron-x-hard-up: "\e935";
$icon-tulli-close: "\e92a";
$icon-tulli-delete: "\e92b";
$icon-tulli-edit: "\e915";
$icon-tulli-file-add: "\e922";
$icon-tulli-help: "\e91c";
$icon-tulli-help-bold: "\e91b";
$icon-tulli-info: "\e917";
$icon-tulli-info-roman: "\e919";
$icon-tulli-liitu: "\e91f";
$icon-tulli-lock-open: "\e91a";
$icon-tulli-message: "\e916";
$icon-tulli-pdf: "\e903";
$icon-tulli-pin-available: "\e925";
$icon-tulli-pin-pinned: "\e924";
$icon-tulli-printer: "\e92c";
$icon-tulli-radio-selected: "\e929";
$icon-tulli-radio-unselected: "\e926";
$icon-tulli-search: "\e923";
$icon-tulli-service-ahvenanmaa: "\e900";
$icon-tulli-service-customer: "\e904";
$icon-tulli-service-intrastat: "\e91d";
$icon-tulli-service-luvat: "\e905";
$icon-tulli-service-passitus: "\e907";
$icon-tulli-service-tullaus: "\e908";
$icon-tulli-service-tullaus-export: "\e90a";
$icon-tulli-service-tullaus-import: "\e90b";
$icon-tulli-service-tullivarastointi: "\e909";
$icon-tulli-service-turvatieto: "\e901";
$icon-tulli-service-uljas: "\e906";
$icon-tulli-settings: "\e92d";
$icon-tulli-settings-dropdown: "\e927";
$icon-tulli-user: "\e928";
