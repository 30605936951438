$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          14px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * .85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * .85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") !default;

//** File name for all font files.
$icon-font-name:          "glyphicons-halflings-regular" !default;
//** Element ID within SVG icon file.
$icon-font-svg-id:        "glyphicons_halflingsregular" !default;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;

$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color:                    $gray !default;
//** `<input>` border color
$input-border:                   #ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:             #66afe9 !default;

//** Placeholder text color
$input-color-placeholder:        #999 !default;

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//** `.form-group` margin
$form-group-margin-bottom:       15px !default;

$legend-color:                   $gray-dark !default;
$legend-border-color:            #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff !default;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0, 0, 0, .15) !default;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5 !default;

//** Dropdown link text color.
$dropdown-link-color:            $gray-dark !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5 !default;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg !default;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-light !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-light !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000 !default;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    50px !default;
$navbar-margin-bottom:             $line-height-computed !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width * 0.5)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) * 0.5) !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             #777 !default;
$navbar-default-bg:                #f8f8f8 !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color:                #777 !default;
$navbar-default-link-hover-color:          #333 !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #888 !default;
$navbar-default-toggle-border-color:       #ddd !default;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #222 !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        #333 !default;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px !default;
$nav-link-hover-bg:                         $gray-lighter !default;

$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;

//== Tabs
$nav-tabs-border-color:                     #ddd !default;

$nav-tabs-link-hover-border-color:          $gray-lighter !default;

$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color:   #ddd !default;

$nav-tabs-justified-link-border-color:            #ddd !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Pills
$nav-pills-border-radius:                   $border-radius-base !default;
$nav-pills-active-link-hover-bg:            $component-active-bg !default;
$nav-pills-active-link-hover-color:         $component-active-color !default;


//== Pagination
//
//##

$pagination-color:                     $link-color !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;


//== Pager
//
//##

$pager-bg:                             $pagination-bg !default;
$pager-border:                         $pagination-border !default;
$pager-border-radius:                  15px !default;

$pager-hover-bg:                       $pagination-hover-bg !default;

$pager-active-bg:                      $pagination-active-bg !default;
$pager-active-color:                   $pagination-active-color !default;

$pager-disabled-color:                 $pagination-disabled-color !default;


//== Jumbotron
//
//##

$jumbotron-padding:              30px !default;
$jumbotron-color:                inherit !default;
$jumbotron-bg:                   $gray-lighter !default;
$jumbotron-heading-color:        inherit !default;
$jumbotron-font-size:            ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size:    ceil(($font-size-base * 4.5)) !default;


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px !default;
//** Tooltip text color
$tooltip-color:               #fff !default;
//** Tooltip background color
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;


//== Popovers
//
//##

//** Popover body background color
$popover-bg:                          #fff !default;
//** Popover maximum width
$popover-max-width:                   276px !default;
//** Popover border color
$popover-border-color:                rgba(0, 0, 0, .2) !default;
//** Popover fallback border color
$popover-fallback-border-color:       #ccc !default;

//** Popover title background color
$popover-title-bg:                    darken($popover-bg, 3%) !default;

//** Popover arrow width
$popover-arrow-width:                 10px !default;
//** Popover arrow color
$popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;


//== Labels
//
//##

//** Default label background color
$label-default-bg:            $gray-light !default;
//** Primary label background color
$label-primary-bg:            $brand-primary !default;
//** Success label background color
$label-success-bg:            $brand-success !default;
//** Info label background color
$label-info-bg:               $brand-info !default;
//** Warning label background color
$label-warning-bg:            $brand-warning !default;
//** Danger label background color
$label-danger-bg:             $brand-danger !default;

//** Default label text color
$label-color:                 #fff !default;
//** Default text color of a linked label
$label-link-hover-color:      #fff !default;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         15px !default;

//** Padding applied to the modal title
$modal-title-padding:         15px !default;
//** Modal title line-height
$modal-title-line-height:     $line-height-base !default;

//** Background color of modal content area
$modal-content-bg:                             #fff !default;
//** Modal content border color
$modal-content-border-color:                   rgba(0, 0, 0, .2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color:          #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg:           #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .5 !default;
//** Modal header border color
$modal-header-border-color:   #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius-base !default;
$alert-link-font-weight:      bold !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;


//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg:                 #f5f5f5 !default;
//** Progress bar text color
$progress-bar-color:          #fff !default;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius:      $border-radius-base !default;

//** Default progress bar color
$progress-bar-bg:             $brand-primary !default;
//** Success progress bar color
$progress-bar-success-bg:     $brand-success !default;
//** Warning progress bar color
$progress-bar-warning-bg:     $brand-warning !default;
//** Danger progress bar color
$progress-bar-danger-bg:      $brand-danger !default;
//** Info progress bar color
$progress-bar-info-bg:        $brand-info !default;


//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 #fff !default;
//** `.list-group-item` border color
$list-group-border:             #ddd !default;
//** List group border radius
$list-group-border-radius:      $border-radius-base !default;

//** Background color of single list items on hover
$list-group-hover-bg:           #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color:       $component-active-color !default;
//** Background color of active list items
$list-group-active-bg:          $component-active-bg !default;
//** Border color of active list elements
$list-group-active-border:      $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

//** Text color of disabled list items
$list-group-disabled-color:      $gray-light !default;
//** Background color of disabled list items
$list-group-disabled-bg:         $gray-lighter !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;


//== Panels
//
//##

$panel-bg:                    #fff !default;
$panel-body-padding:          15px !default;
$panel-heading-padding:       10px 15px !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         $border-radius-base !default;

//** Border color for elements within panels
$panel-inner-border:          #ddd !default;
$panel-footer-bg:             #f5f5f5 !default;

$panel-default-text:          $gray-dark !default;
$panel-default-border:        #ddd !default;
$panel-default-heading-bg:    #f5f5f5 !default;

$panel-primary-text:          #fff !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;

$panel-success-text:          $state-success-text !default;
$panel-success-border:        $state-success-border !default;
$panel-success-heading-bg:    $state-success-bg !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-warning-text:          $state-warning-text !default;
$panel-warning-border:        $state-warning-border !default;
$panel-warning-heading-bg:    $state-warning-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px !default;
//** Thumbnail background color
$thumbnail-bg:                $body-bg !default;
//** Thumbnail border color
$thumbnail-border:            #ddd !default;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius-base !default;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $text-color !default;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px !default;


//== Wells
//
//##

$well-bg:                     #f5f5f5 !default;
$well-border:                 darken($well-bg, 7%) !default;


//== Badges
//
//##

$badge-color:                 #fff !default;
//** Linked badge text color on hover
$badge-link-hover-color:      #fff !default;
$badge-bg:                    $gray-light !default;

//** Badge text color in active nav link
$badge-active-color:          $link-color !default;
//** Badge background color in active nav link
$badge-active-bg:             #fff !default;

$badge-font-weight:           bold !default;
$badge-line-height:           1 !default;
$badge-border-radius:         10px !default;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
//** Breadcrumb background color
$breadcrumb-bg:                 #f5f5f5 !default;
//** Breadcrumb text color
$breadcrumb-color:              #ccc !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-light !default;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          "/" !default;


//== Carousel
//
//##

$carousel-text-shadow:                        0 1px 2px rgba(0, 0, 0, .6) !default;

$carousel-control-color:                      #fff !default;
$carousel-control-width:                      15% !default;
$carousel-control-opacity:                    .5 !default;
$carousel-control-font-size:                  20px !default;

$carousel-indicator-active-bg:                #fff !default;
$carousel-indicator-border-color:             #fff !default;

$carousel-caption-color:                      #fff !default;


//== Close
//
//##

$close-font-weight:           bold !default;
$close-color:                 #000 !default;
$close-text-shadow:           0 1px 0 #fff !default;


//== Code
//
//##

$code-color:                  #c7254e !default;
$code-bg:                     #f9f2f4 !default;

$kbd-color:                   #fff !default;
$kbd-bg:                      #333 !default;

$pre-bg:                      #f5f5f5 !default;
$pre-color:                   $gray-dark !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   340px !default;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted:                  $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light !default;
//** Headings small color
$headings-small-color:        $gray-light !default;
//** Blockquote small color
$blockquote-small-color:      $gray-light !default;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color:     $gray-lighter !default;
//** Page header border color
$page-header-border-color:    $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint:    $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border:                   $gray-lighter !default;

$tulli-black-color: #000000;
$tulli-blue-color: #00205b;
$tulli-top-bar-color: #4a4b50;
$tulli-dark-gray-color: #6b6b6c;
$tulli-mid-gray-color: #bbbcbc;
$tulli-light-gray-color: #fafafa;
$tulli-light-bluegray-color: #e3e5e9;
$tulli-bg-color: #eeeff2; //#E7EAF0;
$tulli-header-bg-color: #e3e5e9; //#C6CDDC;
$tulli-orange-color: #f26600;
$tulli-table-border-color: #bfcadb;
$tulli-list-odd-color: $tulli-light-gray-color;
$tulli-list-even-color: $tulli-bg-color;
$tulli-list-selected-color: #a7b3ca;
$tulli-list-hover-color: $tulli-list-selected-color;
$tulli-list-active-color: $tulli-table-border-color;
$tulli-white-color: #ffffff;
$tulli-disabled-color: rgba(0, 0, 0, 0.6);
$tulli-disabled-bg-color: #f5f5f5;

$tulli-yellow: #f2a900;

$tulli-success-color: #007b98;
$tulli-info-color: #6183c2;
$tulli-error-color: #c71530;
$tulli-error-bg-color: #fce8eb;
$tulli-btn-warning-color: #fdb700;
$tulli-warning-color: #936a00;
$tulli-warning-bg-color: #fef6e6;

$tulli-blue-hover-color: #19366b;
$tulli-light-hover-color: #e6e6e6;

$tulli-input-focus-color: $tulli-blue-color;
$tulli-disabled-btn-color: #bfcadb;
$tulli-inactive-tab-color: $tulli-disabled-btn-color;
$tulli-hover-tab-color: $tulli-inactive-tab-color;
$tulli-accordion-border-color: #8393af;
$tulli-accordion-background-color: #b2bccd;
$tulli-accordion-hover-background-color: #e5e8ee;
$tulli-login-info-box-background-color: #d9dfec;
$tulli-accordion-grey-border-color: #f1f2f4;
$tulli-input-placeholder: #767676;
$tulli-input-placeholder-error: #786669;
$tulli-input-placeholder-warning: #78715e;

$tulli-btn-focus-shadow: 0 0 10px 2px $tulli-blue-color !important;
$tulli-btn-focus-alt-shadow: 0 0 10px 2px $tulli-white-color !important;
$tulli-field-focus-shadow: 0 0 6px 0 $tulli-blue-color !important;

// Service specific colors
$tulli-passitus-color: #34b09c;
$tulli-tullivarasto-color: #6183c2;
$tulli-intrastat-blue: #64738f;
$tulli-aland: #b09ea1;
$tulli-tullaus: #f2a900;

$tulli-unit: 10px;
$tulli-gutter-width: $tulli-unit * 2;

$tulli-base-font-size: 14px;
$tulli-h1-font-size: 3.6rem;
$tulli-h2-font-size: 2.4rem;
$tulli-h3-font-size: 2rem;
$tulli-h4-font-size: 1.8rem;
$tulli-h5-font-size: 1.5rem;
$tulli-h6-font-size: 1.2rem;
$tulli-font-family-sans-serif: Open Sans, Arial, sans-serif;
$headings-font-weight: bold;

// Tulli icon font characters
$icon-tulli-add: "\e90c";
$icon-tulli-add-bold: "\e91e";
$icon-tulli-arrow-triangle-down: "\e910";
$icon-tulli-arrow-triangle-left: "\e911";
$icon-tulli-arrow-triangle-right: "\e912";
$icon-tulli-arrow-triangle-up: "\e913";
$icon-tulli-arrow-triangle-wide-down: "\e921";
$icon-tulli-arrow-triangle-wide-left: "\e914";
$icon-tulli-arrow-triangle-wide-right: "\e92e";
$icon-tulli-arrow-triangle-wide-up: "\e92f";
$icon-tulli-attention: "\e918";
$icon-tulli-calendar: "\e902";
$icon-tulli-checkbox-checked: "\e90d";
$icon-tulli-checkbox-unchecked: "\e90e";
$icon-tulli-chevron-down: "\e930";
$icon-tulli-chevron-left: "\e90f";
$icon-tulli-chevron-right: "\e931";
$icon-tulli-chevron-up: "\e932";
$icon-tulli-chevron-x-hard-down: "\e933";
$icon-tulli-chevron-x-hard-left: "\e920";
$icon-tulli-chevron-x-hard-right: "\e934";
$icon-tulli-chevron-x-hard-up: "\e935";
$icon-tulli-close: "\e92a";
$icon-tulli-delete: "\e92b";
$icon-tulli-edit: "\e915";
$icon-tulli-file-add: "\e922";
$icon-tulli-help: "\e91c";
$icon-tulli-help-bold: "\e91b";
$icon-tulli-info: "\e917";
$icon-tulli-info-roman: "\e919";
$icon-tulli-liitu: "\e91f";
$icon-tulli-lock-open: "\e91a";
$icon-tulli-message: "\e916";
$icon-tulli-pdf: "\e903";
$icon-tulli-pin-available: "\e925";
$icon-tulli-pin-pinned: "\e924";
$icon-tulli-printer: "\e92c";
$icon-tulli-radio-selected: "\e929";
$icon-tulli-radio-unselected: "\e926";
$icon-tulli-search: "\e923";
$icon-tulli-service-ahvenanmaa: "\e900";
$icon-tulli-service-customer: "\e904";
$icon-tulli-service-intrastat: "\e91d";
$icon-tulli-service-luvat: "\e905";
$icon-tulli-service-passitus: "\e907";
$icon-tulli-service-tullaus: "\e908";
$icon-tulli-service-tullaus-export: "\e90a";
$icon-tulli-service-tullaus-import: "\e90b";
$icon-tulli-service-tullivarastointi: "\e909";
$icon-tulli-service-turvatieto: "\e901";
$icon-tulli-service-uljas: "\e906";
$icon-tulli-settings: "\e92d";
$icon-tulli-settings-dropdown: "\e927";
$icon-tulli-user: "\e928";

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus, .focused {
    border-color: $color;
    outline: 0;
    box-shadow: 0 0 0 1px $color;
  }
  &:focus {
    -webkit-box-shadow: $tulli-field-focus-shadow;
    box-shadow: $tulli-field-focus-shadow;
  }
}

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    &:focus, .focused {
      border-color: $border-color;
      box-shadow: 0 0 0 1px $border-color;
    }
    &:focus {
      -webkit-box-shadow: $tulli-field-focus-shadow;
      box-shadow: $tulli-field-focus-shadow;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 25%);
  }
  &:focus {
    -webkit-box-shadow: $tulli-btn-focus-shadow;
    box-shadow: $tulli-btn-focus-shadow;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 6%);
        border-color: darken($border, 6%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
    &:focus {
      -webkit-box-shadow: $tulli-btn-focus-shadow;
      box-shadow: $tulli-btn-focus-shadow;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  /*
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }
  */

  .badge {
    color: $background;
    background-color: $color;
  }
}

div[role="button"]:focus {
  -webkit-box-shadow: $tulli-btn-focus-shadow;
  box-shadow: $tulli-btn-focus-shadow;
}

$font-path: "../fonts" !default;
@font-face {
  font-family: "Tulli Icons";
  src: url("#{$font-path}/Tulli-Icons.eot?9eemfb");
  src: url("#{$font-path}/Tulli-Icons.eot?9eemfb#iefix") format("embedded-opentype"),
  url("#{$font-path}/Tulli-Icons.ttf?9eemfb") format("truetype"),
  url("#{$font-path}/Tulli-Icons.woff?9eemfb") format("woff"),
  url("#{$font-path}/Tulli-Icons.svg?9eemfb#Tulli-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "Tulli Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: 0.3rem;
  margin-right: 0.3rem;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon-blue {
    color: $tulli-blue-color !important;
  }

  &.icon-white {
    color: $tulli-white-color !important;
  }

  &.icon-xs {
    font-size: 0.45rem !important;
  }

  &.icon-sm {
    font-size: 0.8rem !important;
    line-height: 1.9rem !important;
  }

  &.icon-md {
    font-size: 1.6rem !important;
  }

  &.icon-lg {
    font-size: 2.5rem !important;
    line-height: 4rem;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  &.icon-xlg {
    font-size: 4rem !important;
  }

  &.icon-huge {
    font-size: 8rem !important;
  }

  &.icon-form {
    padding-right: $tulli-unit*0.5;
  }

  &.media-object {
    text-align: center;
    padding: $tulli-unit * 1.5;
    width: 60px;
  }

  &.pull-left {
    margin: 0 $tulli-unit * 2 $tulli-unit * 2 0;
  }

  &.pull-right {
    margin: 0 0 $tulli-unit * 2 $tulli-unit * 2;
  }
}

:global {
  .btn:not(.btn-link):not(.btn-compact) .tulli-icon {
    font-size: 1.6rem;
    margin-left: 0;
    margin-right: $tulli-unit * 0.8;

    &:before {
      position: relative;
      top: 2px;
    }
  }

  .btn-icon-right .tulli-icon {
    margin-right: 0;
    margin-left: $tulli-unit * 0.8;
  }
}

.icon-tulli-product-animal-acc-birdcage:before {
  content: "\ea50";
}

.icon-tulli-product-animal-acc-brush:before {
  content: "\ea51";
}

.icon-tulli-product-animal-acc-catcarrier:before {
  content: "\ea52";
}

.icon-tulli-product-animal-acc-catscratch:before {
  content: "\ea53";
}

.icon-tulli-product-animal-acc-collar:before {
  content: "\ea54";
}

.icon-tulli-product-animal-acc-muzzel:before {
  content: "\ea55";
}

.icon-tulli-product-animal-bear:before {
  content: "\ea56";
}

.icon-tulli-product-animal-bee:before {
  content: "\ea57";
}

.icon-tulli-product-animal-beetle:before {
  content: "\ea58";
}

.icon-tulli-product-animal-cat:before {
  content: "\ea59";
}

.icon-tulli-product-animal-chameleon:before {
  content: "\ea5a";
}

.icon-tulli-product-animal-chicken:before {
  content: "\ea5b";
}

.icon-tulli-product-animal-cow:before {
  content: "\ea5c";
}

.icon-tulli-product-animal-crab:before {
  content: "\ea5d";
}

.icon-tulli-product-animal-cricket:before {
  content: "\ea5e";
}

.icon-tulli-product-animal-deer:before {
  content: "\ea5f";
}

.icon-tulli-product-animal-dolphin:before {
  content: "\ea60";
}

.icon-tulli-product-animal-duck:before {
  content: "\ea61";
}

.icon-tulli-product-animal-eagle:before {
  content: "\ea62";
}

.icon-tulli-product-animal-elephant:before {
  content: "\ea63";
}

.icon-tulli-product-animal-exoticfish:before {
  content: "\ea64";
}

.icon-tulli-product-animal-frog:before {
  content: "\ea65";
}

.icon-tulli-product-animal-gorilla:before {
  content: "\ea66";
}

.icon-tulli-product-animal-hedgehog:before {
  content: "\ea67";
}

.icon-tulli-product-animal-kangaroo:before {
  content: "\ea68";
}

.icon-tulli-product-animal-ladybug:before {
  content: "\ea69";
}

.icon-tulli-product-animal-lion:before {
  content: "\ea6a";
}

.icon-tulli-product-animal-lobster:before {
  content: "\ea6b";
}

.icon-tulli-product-animal-mouse:before {
  content: "\ea6c";
}

.icon-tulli-product-animal-octopus:before {
  content: "\ea6d";
}

.icon-tulli-product-animal-owl:before {
  content: "\ea6e";
}

.icon-tulli-product-animal-parrot:before {
  content: "\ea6f";
}

.icon-tulli-product-animal-peacock:before {
  content: "\ea70";
}

.icon-tulli-product-animal-penguin:before {
  content: "\ea71";
}

.icon-tulli-product-animal-rabbit:before {
  content: "\ea72";
}

.icon-tulli-product-animal-racoon:before {
  content: "\ea73";
}

.icon-tulli-product-animal-rhino:before {
  content: "\ea74";
}

.icon-tulli-product-animal-rooster:before {
  content: "\ea75";
}

.icon-tulli-product-animal-seahorse:before {
  content: "\ea76";
}

.icon-tulli-product-animal-shark:before {
  content: "\ea77";
}

.icon-tulli-product-animal-sheep:before {
  content: "\ea78";
}

.icon-tulli-product-animal-shrimp:before {
  content: "\ea79";
}

.icon-tulli-product-animal-skunk:before {
  content: "\ea7a";
}

.icon-tulli-product-animal-snail:before {
  content: "\ea7b";
}

.icon-tulli-product-animal-snake:before {
  content: "\ea7c";
}

.icon-tulli-product-animal-spider:before {
  content: "\ea7d";
}

.icon-tulli-product-animal-swan:before {
  content: "\ea7e";
}

.icon-tulli-product-animal-turtle:before {
  content: "\ea7f";
}

.icon-tulli-product-baby-bib:before {
  content: "\ea80";
}

.icon-tulli-product-baby-bottle:before {
  content: "\ea81";
}

.icon-tulli-product-baby-crib:before {
  content: "\ea82";
}

.icon-tulli-product-baby-diaper:before {
  content: "\ea83";
}

.icon-tulli-product-baby-mobile:before {
  content: "\ea84";
}

.icon-tulli-product-baby-pacifier:before {
  content: "\ea85";
}

.icon-tulli-product-beauty-bobbypin:before {
  content: "\ea86";
}

.icon-tulli-product-beauty-brush:before {
  content: "\ea87";
}

.icon-tulli-product-beauty-comb:before {
  content: "\ea88";
}

.icon-tulli-product-beauty-comb01:before {
  content: "\ea89";
}

.icon-tulli-product-beauty-dryer:before {
  content: "\ea8a";
}

.icon-tulli-product-beauty-extension:before {
  content: "\ea8b";
}

.icon-tulli-product-beauty-hairbrush:before {
  content: "\ea8c";
}

.icon-tulli-product-beauty-hairclipper:before {
  content: "\ea8d";
}

.icon-tulli-product-beauty-hairdress:before {
  content: "\ea8e";
}

.icon-tulli-product-beauty-hairroller:before {
  content: "\ea8f";
}

.icon-tulli-product-beauty-hygiene:before {
  content: "\ea90";
}

.icon-tulli-product-beauty-lotion:before {
  content: "\ea91";
}

.icon-tulli-product-beauty-makeup-brush:before {
  content: "\ea92";
}

.icon-tulli-product-beauty-makeup-brushset:before {
  content: "\ea93";
}

.icon-tulli-product-beauty-makeup-lipstick:before {
  content: "\ea94";
}

.icon-tulli-product-beauty-makeup-mascara:before {
  content: "\ea95";
}

.icon-tulli-product-beauty-makeup-nailpolish:before {
  content: "\ea96";
}

.icon-tulli-product-beauty-mirror:before {
  content: "\ea97";
}

.icon-tulli-product-beauty-razor:before {
  content: "\ea98";
}

.icon-tulli-product-beauty-soap-hand:before {
  content: "\ea99";
}

.icon-tulli-product-beauty-soap:before {
  content: "\ea9a";
}

.icon-tulli-product-beauty-toothbrush-electric:before {
  content: "\ea9b";
}

.icon-tulli-product-beauty-toothbrush:before {
  content: "\ea9c";
}

.icon-tulli-product-beauty-toothpaste:before {
  content: "\ea9d";
}

.icon-tulli-product-beauty-wig:before {
  content: "\ea9e";
}

.icon-tulli-product-clothes-baby-body:before {
  content: "\ea9f";
}

.icon-tulli-product-clothes-baby:before {
  content: "\eaa0";
}

.icon-tulli-product-clothes-bra:before {
  content: "\eaa1";
}

.icon-tulli-product-clothes-glove:before {
  content: "\eaa2";
}

.icon-tulli-product-clothes-hair-tie:before {
  content: "\eaa3";
}

.icon-tulli-product-clothes-handkerchief:before {
  content: "\eaa4";
}

.icon-tulli-product-clothes-hat-beanie:before {
  content: "\eaa5";
}

.icon-tulli-product-clothes-hat-hat:before {
  content: "\eaa6";
}

.icon-tulli-product-clothes-hat-sombrero:before {
  content: "\eaa7";
}

.icon-tulli-product-clothes-purse:before {
  content: "\eaa8";
}

.icon-tulli-product-clothes-scarf:before {
  content: "\eaa9";
}

.icon-tulli-product-clothes-socks:before {
  content: "\eaaa";
}

.icon-tulli-product-clothes-swimset-female:before {
  content: "\eaab";
}

.icon-tulli-product-clothes-swimset-male:before {
  content: "\eaac";
}

.icon-tulli-product-clothes-underwear-female:before {
  content: "\eaad";
}

.icon-tulli-product-clothes-underwear-male:before {
  content: "\eaae";
}

.icon-tulli-product-clothes-wallet:before {
  content: "\eaaf";
}

.icon-tulli-product-crime-alarm:before {
  content: "\eab0";
}

.icon-tulli-product-crime-cctv:before {
  content: "\eab1";
}

.icon-tulli-product-crime-mask:before {
  content: "\eab2";
}

.icon-tulli-product-decoration-animalprize:before {
  content: "\eab3";
}

.icon-tulli-product-decoration-asianentrance:before {
  content: "\eab4";
}

.icon-tulli-product-decoration-babushka:before {
  content: "\eab5";
}

.icon-tulli-product-decoration-blanket:before {
  content: "\eab6";
}

.icon-tulli-product-decoration-blinders:before {
  content: "\eab7";
}

.icon-tulli-product-decoration-bonsai:before {
  content: "\eab8";
}

.icon-tulli-product-decoration-carpet:before {
  content: "\eab9";
}

.icon-tulli-product-decoration-cloth:before {
  content: "\eaba";
}

.icon-tulli-product-decoration-curtain:before {
  content: "\eabb";
}

.icon-tulli-product-decoration-painting:before {
  content: "\eabc";
}

.icon-tulli-product-decoration-picture:before {
  content: "\eabd";
}

.icon-tulli-product-decoration-statue:before {
  content: "\eabe";
}

.icon-tulli-product-decoration-table:before {
  content: "\eabf";
}

.icon-tulli-product-decoration-vase:before {
  content: "\eac0";
}

.icon-tulli-product-decoration-wallpaper:before {
  content: "\eac1";
}

.icon-tulli-product-diving-fins:before {
  content: "\eac2";
}

.icon-tulli-product-diving-harpoon:before {
  content: "\eac3";
}

.icon-tulli-product-diving-mask:before {
  content: "\eac4";
}

.icon-tulli-product-diving-tank:before {
  content: "\eac5";
}

.icon-tulli-product-entertainment-balloons:before {
  content: "\eac6";
}

.icon-tulli-product-entertainment-helium:before {
  content: "\eac7";
}

.icon-tulli-product-entertainment-slotmachine:before {
  content: "\eac8";
}

.icon-tulli-product-explosives-bomb:before {
  content: "\eac9";
}

.icon-tulli-product-explosives-detonator:before {
  content: "\eaca";
}

.icon-tulli-product-explosives-fireworks:before {
  content: "\eacb";
}

.icon-tulli-product-explosives-tnt:before {
  content: "\eacc";
}

.icon-tulli-product-fishing-bobber:before {
  content: "\eacd";
}

.icon-tulli-product-fishing-lure:before {
  content: "\eace";
}

.icon-tulli-product-fishing-net:before {
  content: "\eacf";
}

.icon-tulli-product-fishing-rod:before {
  content: "\ead0";
}

.icon-tulli-product-food-banana:before {
  content: "\ead1";
}

.icon-tulli-product-food-candy:before {
  content: "\ead2";
}

.icon-tulli-product-food-cantine:before {
  content: "\ead3";
}

.icon-tulli-product-food-champagne:before {
  content: "\ead4";
}

.icon-tulli-product-food-chips:before {
  content: "\ead5";
}

.icon-tulli-product-food-cocobeans:before {
  content: "\ead6";
}

.icon-tulli-product-food-coffee-thermos:before {
  content: "\ead7";
}

.icon-tulli-product-food-coffee:before {
  content: "\ead8";
}

.icon-tulli-product-food-cookie:before {
  content: "\ead9";
}

.icon-tulli-product-food-musli:before {
  content: "\eada";
}

.icon-tulli-product-food-pasta:before {
  content: "\eadb";
}

.icon-tulli-product-food-seasoning:before {
  content: "\eadc";
}

.icon-tulli-product-food-soda-bottle:before {
  content: "\eadd";
}

.icon-tulli-product-food-soda-can:before {
  content: "\eade";
}

.icon-tulli-product-food-soda-energy:before {
  content: "\eadf";
}

.icon-tulli-product-food-tea:before {
  content: "\eae0";
}

.icon-tulli-product-furniture-bench:before {
  content: "\eae1";
}

.icon-tulli-product-furniture-bookshelf:before {
  content: "\eae2";
}

.icon-tulli-product-furniture-dining-chair:before {
  content: "\eae3";
}

.icon-tulli-product-furniture-dresser:before {
  content: "\eae4";
}

.icon-tulli-product-furniture-dresser1:before {
  content: "\eae5";
}

.icon-tulli-product-furniture-dressing:before {
  content: "\eae6";
}

.icon-tulli-product-furniture-fireplace:before {
  content: "\eae7";
}

.icon-tulli-product-furniture-handwash:before {
  content: "\eae8";
}

.icon-tulli-product-furniture-livingroom-chair:before {
  content: "\eae9";
}

.icon-tulli-product-furniture-mattress-air:before {
  content: "\eaea";
}

.icon-tulli-product-furniture-mattress:before {
  content: "\eaeb";
}

.icon-tulli-product-furniture-pillow:before {
  content: "\eaec";
}

.icon-tulli-product-furniture-rack:before {
  content: "\eaed";
}

.icon-tulli-product-furniture-retrochair:before {
  content: "\eaee";
}

.icon-tulli-product-furniture-shower:before {
  content: "\eaef";
}

.icon-tulli-product-furniture-stairs:before {
  content: "\eaf0";
}

.icon-tulli-product-furniture-table:before {
  content: "\eaf1";
}

.icon-tulli-product-furniture-toilet:before {
  content: "\eaf2";
}

.icon-tulli-product-furniture-tub:before {
  content: "\eaf3";
}

.icon-tulli-product-hazardous-cleaning:before {
  content: "\eaf4";
}

.icon-tulli-product-hazardous-corrosive:before {
  content: "\eaf5";
}

.icon-tulli-product-hazardous-electricity:before {
  content: "\eaf6";
}

.icon-tulli-product-hazardous-radioactive:before {
  content: "\eaf7";
}

.icon-tulli-product-health-ambulancebed:before {
  content: "\eaf8";
}

.icon-tulli-product-health-bandage:before {
  content: "\eaf9";
}

.icon-tulli-product-health-bloodbag:before {
  content: "\eafa";
}

.icon-tulli-product-health-bloodpressure:before {
  content: "\eafb";
}

.icon-tulli-product-health-condom:before {
  content: "\eafc";
}

.icon-tulli-product-health-crutch:before {
  content: "\eafd";
}

.icon-tulli-product-health-facemask:before {
  content: "\eafe";
}

.icon-tulli-product-health-handsanitizer:before {
  content: "\eaff";
}

.icon-tulli-product-health-hearingaid:before {
  content: "\eb00";
}

.icon-tulli-product-health-incense:before {
  content: "\eb01";
}

.icon-tulli-product-health-periodpad:before {
  content: "\eb02";
}

.icon-tulli-product-health-pulsemeter:before {
  content: "\eb03";
}

.icon-tulli-product-health-sleepapnea:before {
  content: "\eb04";
}

.icon-tulli-product-health-stethoscope:before {
  content: "\eb05";
}

.icon-tulli-product-health-syringe:before {
  content: "\eb06";
}

.icon-tulli-product-health-tampon:before {
  content: "\eb07";
}

.icon-tulli-product-health-toiletpaper:before {
  content: "\eb08";
}

.icon-tulli-product-health-walkingaid:before {
  content: "\eb09";
}

.icon-tulli-product-health-weights:before {
  content: "\eb0a";
}

.icon-tulli-product-hobbies-kite:before {
  content: "\eb0b";
}

.icon-tulli-product-holiday-easter:before {
  content: "\eb0c";
}

.icon-tulli-product-holiday-halloween:before {
  content: "\eb0d";
}

.icon-tulli-product-holiday-snowglobe:before {
  content: "\eb0e";
}

.icon-tulli-product-holiday-snowman:before {
  content: "\eb0f";
}

.icon-tulli-product-holiday-xmas-bauble:before {
  content: "\eb10";
}

.icon-tulli-product-holiday-xmas-bells:before {
  content: "\eb11";
}

.icon-tulli-product-holiday-xmas-cane:before {
  content: "\eb12";
}

.icon-tulli-product-holiday-xmas-door:before {
  content: "\eb13";
}

.icon-tulli-product-holiday-xmas-lights:before {
  content: "\eb14";
}

.icon-tulli-product-holiday-xmas-tree:before {
  content: "\eb15";
}

.icon-tulli-product-houseware-bowl:before {
  content: "\eb16";
}

.icon-tulli-product-houseware-cutlery:before {
  content: "\eb17";
}

.icon-tulli-product-houseware-eatingset:before {
  content: "\eb18";
}

.icon-tulli-product-houseware-ladle:before {
  content: "\eb19";
}

.icon-tulli-product-houseware-pan:before {
  content: "\eb1a";
}

.icon-tulli-product-houseware-platter:before {
  content: "\eb1b";
}

.icon-tulli-product-houseware-spatula:before {
  content: "\eb1c";
}

.icon-tulli-product-light-alarm:before {
  content: "\eb1d";
}

.icon-tulli-product-light-candle:before {
  content: "\eb1e";
}

.icon-tulli-product-light-ceiling-chandelier:before {
  content: "\eb1f";
}

.icon-tulli-product-light-ceiling-disco:before {
  content: "\eb20";
}

.icon-tulli-product-light-ceiling-lamp:before {
  content: "\eb21";
}

.icon-tulli-product-light-desktoplamp:before {
  content: "\eb22";
}

.icon-tulli-product-light-flashlight:before {
  content: "\eb23";
}

.icon-tulli-product-light-lantern:before {
  content: "\eb24";
}

.icon-tulli-product-light-lavalamp:before {
  content: "\eb25";
}

.icon-tulli-product-light-led:before {
  content: "\eb26";
}

.icon-tulli-product-light-ledstrip:before {
  content: "\eb27";
}

.icon-tulli-product-light-lightbulb-eco:before {
  content: "\eb28";
}

.icon-tulli-product-light-lightbulb-filament:before {
  content: "\eb29";
}

.icon-tulli-product-light-lightbulb-wifi:before {
  content: "\eb2a";
}

.icon-tulli-product-light-lightbulb:before {
  content: "\eb2b";
}

.icon-tulli-product-light-lighter:before {
  content: "\eb2c";
}

.icon-tulli-product-light-oillamp:before {
  content: "\eb2d";
}

.icon-tulli-product-light-purewhite-health:before {
  content: "\eb2e";
}

.icon-tulli-product-light-standing:before {
  content: "\eb2f";
}

.icon-tulli-product-light-studio:before {
  content: "\eb30";
}

.icon-tulli-product-light-tablecandles:before {
  content: "\eb31";
}

.icon-tulli-product-light-tablelamp-glassart:before {
  content: "\eb32";
}

.icon-tulli-product-light-tablelamp:before {
  content: "\eb33";
}

.icon-tulli-product-material-antlers:before {
  content: "\eb34";
}

.icon-tulli-product-material-planks:before {
  content: "\eb35";
}

.icon-tulli-product-material-shell:before {
  content: "\eb36";
}

.icon-tulli-product-material-tusk:before {
  content: "\eb37";
}

.icon-tulli-product-material-wood:before {
  content: "\eb38";
}

.icon-tulli-product-medicine-drugsheet:before {
  content: "\eb39";
}

.icon-tulli-product-medicine-mortar:before {
  content: "\eb3a";
}

.icon-tulli-product-medicine-ointment:before {
  content: "\eb3b";
}

.icon-tulli-product-music-accordian:before {
  content: "\eb3c";
}

.icon-tulli-product-music-banjo:before {
  content: "\eb3d";
}

.icon-tulli-product-music-contrabass:before {
  content: "\eb3e";
}

.icon-tulli-product-music-dholak:before {
  content: "\eb3f";
}

.icon-tulli-product-music-dj:before {
  content: "\eb40";
}

.icon-tulli-product-music-drums:before {
  content: "\eb41";
}

.icon-tulli-product-music-electricguitar:before {
  content: "\eb42";
}

.icon-tulli-product-music-harp:before {
  content: "\eb43";
}

.icon-tulli-product-music-horn:before {
  content: "\eb44";
}

.icon-tulli-product-music-maracas:before {
  content: "\eb45";
}

.icon-tulli-product-music-mic:before {
  content: "\eb46";
}

.icon-tulli-product-music-panflute:before {
  content: "\eb47";
}

.icon-tulli-product-music-piano:before {
  content: "\eb48";
}

.icon-tulli-product-music-saxophone:before {
  content: "\eb49";
}

.icon-tulli-product-music-tambourine:before {
  content: "\eb4a";
}

.icon-tulli-product-music-trumpet:before {
  content: "\eb4b";
}

.icon-tulli-product-music-tuba:before {
  content: "\eb4c";
}

.icon-tulli-product-optic-binocular:before {
  content: "\eb4d";
}

.icon-tulli-product-optic-cameralens:before {
  content: "\eb4e";
}

.icon-tulli-product-optic-glasses-monocle:before {
  content: "\eb4f";
}

.icon-tulli-product-optic-glasses-sun:before {
  content: "\eb50";
}

.icon-tulli-product-optic-glasses:before {
  content: "\eb51";
}

.icon-tulli-product-optic-magnifying:before {
  content: "\eb52";
}

.icon-tulli-product-optic-microscope:before {
  content: "\eb53";
}

.icon-tulli-product-optic-nightvision:before {
  content: "\eb54";
}

.icon-tulli-product-optic-riflescope:before {
  content: "\eb55";
}

.icon-tulli-product-optic-telescope:before {
  content: "\eb56";
}

.icon-tulli-product-other-rubber-doorstop:before {
  content: "\eb57";
}

.icon-tulli-product-other-rubber-drainstop:before {
  content: "\eb58";
}

.icon-tulli-product-other-washhands:before {
  content: "\eb59";
}

.icon-tulli-product-outdoor-sleepingbag:before {
  content: "\eb5a";
}

.icon-tulli-product-outdoor-tent:before {
  content: "\eb5b";
}

.icon-tulli-product-school-clipper:before {
  content: "\eb5c";
}

.icon-tulli-product-school-eraser:before {
  content: "\eb5d";
}

.icon-tulli-product-school-glue:before {
  content: "\eb5e";
}

.icon-tulli-product-school-holepunch:before {
  content: "\eb5f";
}

.icon-tulli-product-school-stapler:before {
  content: "\eb60";
}

.icon-tulli-product-school-tape:before {
  content: "\eb61";
}

.icon-tulli-product-sea-anchor:before {
  content: "\eb62";
}

.icon-tulli-product-sea-oars:before {
  content: "\eb63";
}

.icon-tulli-product-sea-paddle:before {
  content: "\eb64";
}

.icon-tulli-product-sea-rope:before {
  content: "\eb65";
}

.icon-tulli-product-sewing-button:before {
  content: "\eb66";
}

.icon-tulli-product-sewing-needle-yarn:before {
  content: "\eb67";
}

.icon-tulli-product-sewing-needle:before {
  content: "\eb68";
}

.icon-tulli-product-sewing-pin:before {
  content: "\eb69";
}

.icon-tulli-product-sewing-thread:before {
  content: "\eb6a";
}

.icon-tulli-product-sewing-yarnball:before {
  content: "\eb6b";
}

.icon-tulli-product-spareparts-doorhandle:before {
  content: "\eb6c";
}

.icon-tulli-product-spareparts-faucet:before {
  content: "\eb6d";
}

.icon-tulli-product-spareparts-hinge:before {
  content: "\eb6e";
}

.icon-tulli-product-spareparts-lamp:before {
  content: "\eb6f";
}

.icon-tulli-product-spareparts-nails:before {
  content: "\eb70";
}

.icon-tulli-product-spareparts-nuts:before {
  content: "\eb71";
}

.icon-tulli-product-spareparts-screw:before {
  content: "\eb72";
}

.icon-tulli-product-sport-archery:before {
  content: "\eb73";
}

.icon-tulli-product-sport-bowling:before {
  content: "\eb74";
}

.icon-tulli-product-sport-boxing:before {
  content: "\eb75";
}

.icon-tulli-product-sport-fencing:before {
  content: "\eb76";
}

.icon-tulli-product-sport-golf:before {
  content: "\eb77";
}

.icon-tulli-product-sport-helmet:before {
  content: "\eb78";
}

.icon-tulli-product-sport-hockeystick:before {
  content: "\eb79";
}

.icon-tulli-product-sport-iceskates:before {
  content: "\eb7a";
}

.icon-tulli-product-sport-jumprope:before {
  content: "\eb7b";
}

.icon-tulli-product-sport-martialarts:before {
  content: "\eb7c";
}

.icon-tulli-product-sport-rollerblade:before {
  content: "\eb7d";
}

.icon-tulli-product-sport-skateboard:before {
  content: "\eb7e";
}

.icon-tulli-product-sport-skiis:before {
  content: "\eb7f";
}

.icon-tulli-product-sport-snowboard:before {
  content: "\eb80";
}

.icon-tulli-product-sport-soccer:before {
  content: "\eb81";
}

.icon-tulli-product-tech-ac-fan:before {
  content: "\eb82";
}

.icon-tulli-product-tech-ac-heater:before {
  content: "\eb83";
}

.icon-tulli-product-tech-ac-wall:before {
  content: "\eb84";
}

.icon-tulli-product-tech-camera-battery:before {
  content: "\eb85";
}

.icon-tulli-product-tech-camera-film:before {
  content: "\eb86";
}

.icon-tulli-product-tech-camera-selfiestick:before {
  content: "\eb87";
}

.icon-tulli-product-tech-camera-tripod:before {
  content: "\eb88";
}

.icon-tulli-product-tech-car-barcode:before {
  content: "\eb89";
}

.icon-tulli-product-tech-firealarm:before {
  content: "\eb8a";
}

.icon-tulli-product-tech-firedetector:before {
  content: "\eb8b";
}

.icon-tulli-product-tech-floppydisk:before {
  content: "\eb8c";
}

.icon-tulli-product-tech-game-console:before {
  content: "\eb8d";
}

.icon-tulli-product-tech-game-controller:before {
  content: "\eb8e";
}

.icon-tulli-product-tech-keyboard:before {
  content: "\eb8f";
}

.icon-tulli-product-tech-memorycard:before {
  content: "\eb90";
}

.icon-tulli-product-tech-memorystick:before {
  content: "\eb91";
}

.icon-tulli-product-tech-metaldetector:before {
  content: "\eb92";
}

.icon-tulli-product-tech-mouse:before {
  content: "\eb93";
}

.icon-tulli-product-tech-mriscanner:before {
  content: "\eb94";
}

.icon-tulli-product-tech-music-3dprinter:before {
  content: "\eb95";
}

.icon-tulli-product-tech-music-airpod:before {
  content: "\eb96";
}

.icon-tulli-product-tech-music-cassette:before {
  content: "\eb97";
}

.icon-tulli-product-tech-music-cd:before {
  content: "\eb98";
}

.icon-tulli-product-tech-music-discplayer:before {
  content: "\eb99";
}

.icon-tulli-product-tech-music-equalizer:before {
  content: "\eb9a";
}

.icon-tulli-product-tech-music-gramophone:before {
  content: "\eb9b";
}

.icon-tulli-product-tech-music-guitaramp:before {
  content: "\eb9c";
}

.icon-tulli-product-tech-music-headphones:before {
  content: "\eb9d";
}

.icon-tulli-product-tech-music-homesystem:before {
  content: "\eb9e";
}

.icon-tulli-product-tech-music-mic:before {
  content: "\eb9f";
}

.icon-tulli-product-tech-music-mp3:before {
  content: "\eba0";
}

.icon-tulli-product-tech-music-reel:before {
  content: "\eba1";
}

.icon-tulli-product-tech-music-reel2reel:before {
  content: "\eba2";
}

.icon-tulli-product-tech-music-speakers:before {
  content: "\eba3";
}

.icon-tulli-product-tech-music-vinyl:before {
  content: "\eba4";
}

.icon-tulli-product-tech-rc:before {
  content: "\eba5";
}

.icon-tulli-product-tech-remotecontrol:before {
  content: "\eba6";
}

.icon-tulli-product-tech-roomba:before {
  content: "\eba7";
}

.icon-tulli-product-tech-smartwatch-house:before {
  content: "\eba8";
}

.icon-tulli-product-tech-smartwatch-pulse:before {
  content: "\eba9";
}

.icon-tulli-product-tech-tv-modern:before {
  content: "\ebaa";
}

.icon-tulli-product-tech-tv-retro:before {
  content: "\ebab";
}

.icon-tulli-product-tech-usb:before {
  content: "\ebac";
}

.icon-tulli-product-tobacco-bong:before {
  content: "\ebad";
}

.icon-tulli-product-tools-aggregate:before {
  content: "\ebae";
}

.icon-tulli-product-tools-airtank:before {
  content: "\ebaf";
}

.icon-tulli-product-tools-anvil:before {
  content: "\ebb0";
}

.icon-tulli-product-tools-axe:before {
  content: "\ebb1";
}

.icon-tulli-product-tools-battery:before {
  content: "\ebb2";
}

.icon-tulli-product-tools-cart:before {
  content: "\ebb3";
}

.icon-tulli-product-tools-charger:before {
  content: "\ebb4";
}

.icon-tulli-product-tools-ducttape:before {
  content: "\ebb5";
}

.icon-tulli-product-tools-electricmotor:before {
  content: "\ebb6";
}

.icon-tulli-product-tools-extinguisher:before {
  content: "\ebb7";
}

.icon-tulli-product-tools-farming-sickle:before {
  content: "\ebb8";
}

.icon-tulli-product-tools-food-blender:before {
  content: "\ebb9";
}

.icon-tulli-product-tools-food-coffee:before {
  content: "\ebba";
}

.icon-tulli-product-tools-food-fridge:before {
  content: "\ebbb";
}

.icon-tulli-product-tools-food-handmixer:before {
  content: "\ebbc";
}

.icon-tulli-product-tools-food-microwave:before {
  content: "\ebbd";
}

.icon-tulli-product-tools-food-mixer:before {
  content: "\ebbe";
}

.icon-tulli-product-tools-food-oven:before {
  content: "\ebbf";
}

.icon-tulli-product-tools-food-processor:before {
  content: "\ebc0";
}

.icon-tulli-product-tools-gardening-pots:before {
  content: "\ebc1";
}

.icon-tulli-product-tools-gardening-rake:before {
  content: "\ebc2";
}

.icon-tulli-product-tools-gardening-scissor:before {
  content: "\ebc3";
}

.icon-tulli-product-tools-gardening-sprinkler:before {
  content: "\ebc4";
}

.icon-tulli-product-tools-gardening:before {
  content: "\ebc5";
}

.icon-tulli-product-tools-hammer:before {
  content: "\ebc6";
}

.icon-tulli-product-tools-heatcamera:before {
  content: "\ebc7";
}

.icon-tulli-product-tools-helmet:before {
  content: "\ebc8";
}

.icon-tulli-product-tools-jack:before {
  content: "\ebc9";
}

.icon-tulli-product-tools-ladder:before {
  content: "\ebca";
}

.icon-tulli-product-tools-laundry-iron:before {
  content: "\ebcb";
}

.icon-tulli-product-tools-laundry-machine:before {
  content: "\ebcc";
}

.icon-tulli-product-tools-level:before {
  content: "\ebcd";
}

.icon-tulli-product-tools-measure-laser:before {
  content: "\ebce";
}

.icon-tulli-product-tools-multimeter:before {
  content: "\ebcf";
}

.icon-tulli-product-tools-pickaxe:before {
  content: "\ebd0";
}

.icon-tulli-product-tools-power-chainsaw:before {
  content: "\ebd1";
}

.icon-tulli-product-tools-power-drill:before {
  content: "\ebd2";
}

.icon-tulli-product-tools-power-saw:before {
  content: "\ebd3";
}

.icon-tulli-product-tools-powercable:before {
  content: "\ebd4";
}

.icon-tulli-product-tools-powersocket:before {
  content: "\ebd5";
}

.icon-tulli-product-tools-pressure:before {
  content: "\ebd6";
}

.icon-tulli-product-tools-printer-large:before {
  content: "\ebd7";
}

.icon-tulli-product-tools-printer:before {
  content: "\ebd8";
}

.icon-tulli-product-tools-ruler:before {
  content: "\ebd9";
}

.icon-tulli-product-tools-safetyvest:before {
  content: "\ebda";
}

.icon-tulli-product-tools-saw:before {
  content: "\ebdb";
}

.icon-tulli-product-tools-shovel:before {
  content: "\ebdc";
}

.icon-tulli-product-tools-shredder:before {
  content: "\ebdd";
}

.icon-tulli-product-tools-solarpanel:before {
  content: "\ebde";
}

.icon-tulli-product-tools-sonar:before {
  content: "\ebdf";
}

.icon-tulli-product-tools-spedometer:before {
  content: "\ebe0";
}

.icon-tulli-product-tools-structureindicator:before {
  content: "\ebe1";
}

.icon-tulli-product-tools-thermometer:before {
  content: "\ebe2";
}

.icon-tulli-product-tools-transformer:before {
  content: "\ebe3";
}

.icon-tulli-product-tools-usbcable:before {
  content: "\ebe4";
}

.icon-tulli-product-tools-vacuum:before {
  content: "\ebe5";
}

.icon-tulli-product-tools-voltagemeter:before {
  content: "\ebe6";
}

.icon-tulli-product-tools-weatherstation:before {
  content: "\ebe7";
}

.icon-tulli-product-tools-windturbine-small:before {
  content: "\ebe8";
}

.icon-tulli-product-tools-windturbine:before {
  content: "\ebe9";
}

.icon-tulli-product-toy-chess:before {
  content: "\ebea";
}

.icon-tulli-product-toy-dice-roleplay:before {
  content: "\ebeb";
}

.icon-tulli-product-toy-dices:before {
  content: "\ebec";
}

.icon-tulli-product-toy-dinosaur:before {
  content: "\ebed";
}

.icon-tulli-product-toy-jackinabox:before {
  content: "\ebee";
}

.icon-tulli-product-toy-lego:before {
  content: "\ebef";
}

.icon-tulli-product-toy-music:before {
  content: "\ebf0";
}

.icon-tulli-product-toy-piecehole:before {
  content: "\ebf1";
}

.icon-tulli-product-toy-playingcards:before {
  content: "\ebf2";
}

.icon-tulli-product-toy-rockinghorse:before {
  content: "\ebf3";
}

.icon-tulli-product-toy-swingset:before {
  content: "\ebf4";
}

.icon-tulli-product-toy-train:before {
  content: "\ebf5";
}

.icon-tulli-product-toy-watergun:before {
  content: "\ebf6";
}

.icon-tulli-product-transportation-battery:before {
  content: "\ebf7";
}

.icon-tulli-product-transportation-bicycle:before {
  content: "\ebf8";
}

.icon-tulli-product-transportation-boatwheel:before {
  content: "\ebf9";
}

.icon-tulli-product-transportation-car-mirror:before {
  content: "\ebfa";
}

.icon-tulli-product-transportation-container:before {
  content: "\ebfb";
}

.icon-tulli-product-transportation-dial:before {
  content: "\ebfc";
}

.icon-tulli-product-transportation-helmet:before {
  content: "\ebfd";
}

.icon-tulli-product-transportation-jetski:before {
  content: "\ebfe";
}

.icon-tulli-product-transportation-moped:before {
  content: "\ebff";
}

.icon-tulli-product-transportation-segway:before {
  content: "\ec00";
}

.icon-tulli-product-transportation-steeringwheel:before {
  content: "\ec01";
}

.icon-tulli-product-transportation-truck:before {
  content: "\ec02";
}

.icon-tulli-product-valuable-crown:before {
  content: "\ec03";
}

.icon-tulli-product-valuable-diploma:before {
  content: "\ec04";
}

.icon-tulli-product-valuable-earrings:before {
  content: "\ec05";
}

.icon-tulli-product-valuable-forgery-diamond:before {
  content: "\ec06";
}

.icon-tulli-product-valuable-goldbar:before {
  content: "\ec07";
}

.icon-tulli-product-valuable-honor:before {
  content: "\ec08";
}

.icon-tulli-product-valuable-medal:before {
  content: "\ec09";
}

.icon-tulli-product-valuable-moneybag:before {
  content: "\ec0a";
}

.icon-tulli-product-valuable-necklace-pearl:before {
  content: "\ec0b";
}

.icon-tulli-product-valuable-necklace:before {
  content: "\ec0c";
}

.icon-tulli-product-valuable-pearl:before {
  content: "\ec0d";
}

.icon-tulli-product-valuable-piggybank:before {
  content: "\ec0e";
}

.icon-tulli-product-valuable-purse:before {
  content: "\ec0f";
}

.icon-tulli-product-valuable-safe:before {
  content: "\ec10";
}

.icon-tulli-product-valuable-trophy:before {
  content: "\ec11";
}

.icon-tulli-product-valuable-wallet:before {
  content: "\ec12";
}

.icon-tulli-product-valuable-watch-armband:before {
  content: "\ec13";
}

.icon-tulli-product-valuable-watch-connect:before {
  content: "\ec14";
}

.icon-tulli-product-valuable-watch:before {
  content: "\ec15";
}

.icon-tulli-product-weapon-artillery:before {
  content: "\ec16";
}

.icon-tulli-product-weapon-automatic:before {
  content: "\ec17";
}

.icon-tulli-product-weapon-axe:before {
  content: "\ec18";
}

.icon-tulli-product-weapon-bomb:before {
  content: "\ec19";
}

.icon-tulli-product-weapon-cannon:before {
  content: "\ec1a";
}

.icon-tulli-product-weapon-catapult:before {
  content: "\ec1b";
}

.icon-tulli-product-weapon-launcher:before {
  content: "\ec1c";
}

.icon-tulli-product-weapon-mace:before {
  content: "\ec1d";
}

.icon-tulli-product-weapon-machinegun:before {
  content: "\ec1e";
}

.icon-tulli-product-weapon-pistol:before {
  content: "\ec1f";
}

.icon-tulli-product-weapon-shotgun:before {
  content: "\ec20";
}

.icon-tulli-product-weapon-shuriken:before {
  content: "\ec21";
}

.icon-tulli-product-weapon-sling:before {
  content: "\ec22";
}

.icon-tulli-product-weapon-sword:before {
  content: "\ec23";
}

.icon-tulli-product-weapon-tank:before {
  content: "\ec24";
}

.icon-tulli-product-weapon-tnt:before {
  content: "\ec25";
}

.icon-tulli-service-tullaus:before {
  content: "\e935";
}

.icon-tulli-service-tullaus-export:before {
  content: "\e936";
}

.icon-tulli-service-tullaus-import:before {
  content: "\e937";
}

.icon-tulli-service-ahvenanmaa:before {
  content: "\e92e";
}

.icon-tulli-service-customer:before {
  content: "\e92f";
}

.icon-tulli-service-eori:before {
  content: "\e945";
}

.icon-tulli-service-mrn:before {
  content: "\e946";
}

.icon-tulli-service-sanoma:before {
  content: "\e947";
}

.icon-tulli-service-iat:before {
  content: "\e930";
}

.icon-tulli-service-intrastat:before {
  content: "\e931";
}

.icon-tulli-service-luvat:before {
  content: "\e933";
}

.icon-tulli-service-passitus:before {
  content: "\e934";
}

.icon-tulli-service-tullivarastointi:before {
  content: "\e938";
}

.icon-tulli-service-turvatieto:before {
  content: "\e939";
}

.icon-tulli-service-uljas:before {
  content: "\e93a";
}

.icon-tulli-service-upload:before {
  content: "\e932";
}

.icon-tulli-service-fintaric:before {
  content: "\e924";
}

.icon-tulli-service-arex:before {
  content: "\e94a";
}

.icon-tulli-service-tuttu:before {
  content: "\ea38";
}

.icon-tulli-service-askcustomsadvice:before {
  content: "\e944";
}

.icon-tulli-service-museum:before {
  content: "\e94b";
}

.icon-tulli-service-laboratory:before {
  content: "\e94c";
}

.icon-tulli-service-utu:before {
  content: "\e94d";
}

.icon-tulli-box-default:before {
  content: "\e983";
}

.icon-tulli-box-barcode:before {
  content: "\e984";
}

.icon-tulli-box-export:before {
  content: "\e93b";
}

.icon-tulli-box-import:before {
  content: "\e93c";
}

.icon-tulli-box-question:before {
  content: "\e985";
}

.icon-tulli-box-ok:before {
  content: "\e986";
}

.icon-tulli-box-euro:before {
  content: "\e987";
}

.icon-tulli-box-discount:before {
  content: "\e988";
}

.icon-tulli-box-alert:before {
  content: "\ea35";
}

.icon-tulli-box-search:before {
  content: "\e989";
}

.icon-tulli-box-search-barcode:before {
  content: "\e98a";
}

.icon-tulli-box-return:before {
  content: "\e98b";
}

.icon-tulli-box-forward:before {
  content: "\e98c";
}

.icon-tulli-box-airplane-arrival:before {
  content: "\e98d";
}

.icon-tulli-box-airplane-departure:before {
  content: "\e98e";
}

.icon-tulli-box-ship:before {
  content: "\e98f";
}

.icon-tulli-box-split:before {
  content: "\e990";
}

.icon-tulli-box-size:before {
  content: "\e991";
}

.icon-tulli-box-handle:before {
  content: "\e992";
}

.icon-tulli-box-export-temp:before {
  content: "\ea39";
}

.icon-tulli-box-change:before {
  content: "\ea3a";
}

.icon-tulli-container-lift:before {
  content: "\e993";
}

.icon-tulli-arrow-down:before {
  content: "\e901";
}

.icon-tulli-arrow-left:before {
  content: "\e902";
}

.icon-tulli-arrow-right:before {
  content: "\e903";
}

.icon-tulli-arrow-up:before {
  content: "\e904";
}

.icon-tulli-chevron-down:before {
  content: "\e909";
}

.icon-tulli-chevron-left:before {
  content: "\e90a";
}

.icon-tulli-chevron-right:before {
  content: "\e90b";
}

.icon-tulli-chevron-up:before {
  content: "\e90f";
}

.icon-tulli-chevron-tight-down:before {
  content: "\e910";
}

.icon-tulli-chevron-tight-left:before {
  content: "\e911";
}

.icon-tulli-chevron-tight-right:before {
  content: "\e912";
}

.icon-tulli-chevron-tight-up:before {
  content: "\e913";
}

.icon-tulli-arrow-thin-left:before {
  content: "\e905";
}

.icon-tulli-arrow-thin-right:before {
  content: "\e906";
}

.icon-tulli-checkbox-checked:before {
  content: "\e90d";
}

.icon-tulli-checkbox-unchecked:before {
  content: "\e90e";
}

.icon-tulli-radio-selected:before {
  content: "\e929";
}

.icon-tulli-radio-unselected:before {
  content: "\e928";
}

.icon-tulli-checklist-one:before {
  content: "\e93d";
}

.icon-tulli-task-list:before {
  content: "\e994";
}

.icon-tulli-add:before {
  content: "\e91e";
}

.icon-tulli-subtract:before {
  content: "\e995";
}

.icon-tulli-checkmark:before {
  content: "\e908";
}

.icon-tulli-close:before {
  content: "\e92a";
}

.icon-tulli-row-default:before {
  content: "\e94e";
}

.icon-tulli-row-add:before {
  content: "\e94f";
}

.icon-tulli-row-delete:before {
  content: "\e950";
}

.icon-tulli-treeview:before {
  content: "\e93e";
}

.icon-tulli-edit:before {
  content: "\e915";
}

.icon-tulli-delete:before {
  content: "\e92b";
}

.icon-tulli-save:before {
  content: "\ea3b";
}

.icon-tulli-expand:before {
  content: "\e916";
}

.icon-tulli-electronicmessage:before {
  content: "\e93f";
}

.icon-tulli-external:before {
  content: "\e917";
}

.icon-tulli-eye:before {
  content: "\e90c";
}

.icon-tulli-filter:before {
  content: "\e919";
}

.icon-tulli-filter-filled:before {
  content: "\e996";
}

.icon-tulli-hamburger-menu:before {
  content: "\e91a";
}

.icon-tulli-calendar:before {
  content: "\e907";
}

.icon-tulli-calendar-schedule:before {
  content: "\e997";
}

.icon-tulli-calculator:before {
  content: "\e951";
}

.icon-tulli-statistics:before {
  content: "\e998";
}

.icon-tulli-presentation-performance:before {
  content: "\e999";
}

.icon-tulli-presentation-graph-analytics:before {
  content: "\e99a";
}

.icon-tulli-presentation-graph-pie:before {
  content: "\e99b";
}

.icon-tulli-finance-money-pile:before {
  content: "\e9cd";
}

.icon-tulli-finance-payment-coin:before {
  content: "\ea3c";
}

.icon-tulli-finance-currency-conversion:before {
  content: "\e9ce";
}

.icon-tulli-finance-positive:before {
  content: "\e9d0";
}

.icon-tulli-finance-negative:before {
  content: "\e9cf";
}

.icon-tulli-shopping-basket:before {
  content: "\e9d1";
}

.icon-tulli-shopping-receipt:before {
  content: "\e9d2";
}

.icon-tulli-finance-percentage-coupon:before {
  content: "\e9d3";
}

.icon-tulli-finance-percentage-sign:before {
  content: "\e9d4";
}

.icon-tulli-shopping-givegift:before {
  content: "\e9d5";
}

.icon-tulli-stamp-decision:before {
  content: "\e9d6";
}

.icon-tulli-stamp-question:before {
  content: "\e9d7";
}

.icon-tulli-certified:before {
  content: "\ea3d";
}

.icon-tulli-legal-scale:before {
  content: "\ea3e";
}

.icon-tulli-legal-hammer-default:before {
  content: "\ea3f";
}

.icon-tulli-legal-hammer-refresh:before {
  content: "\ea40";
}

.icon-tulli-finance-saving-bank:before {
  content: "\ea41";
}

.icon-tulli-finance-safe-close:before {
  content: "\ea42";
}

.icon-tulli-finance-safe-open:before {
  content: "\ea43";
}

.icon-tulli-attention:before {
  content: "\e918";
}

.icon-tulli-attention-bold:before {
  content: "\ec27";
}

.icon-tulli-help:before {
  content: "\e91b";
}

.icon-tulli-info:before {
  content: "\e91f";
}

.icon-tulli-info-modern:before {
  content: "\ec26";
}

.icon-tulli-sign-blocked:before {
  content: "\e952";
}

.icon-tulli-sign-stop:before {
  content: "\e953";
}

.icon-tulli-sign-protection:before {
  content: "\e99c";
}

.icon-tulli-risk-globe:before {
  content: "\e99d";
}

.icon-tulli-history:before {
  content: "\e91d";
}

.icon-tulli-integrationscheme:before {
  content: "\e942";
}

.icon-tulli-logout:before {
  content: "\e99e";
}

.icon-tulli-lock-close:before {
  content: "\e921";
}

.icon-tulli-lock-open:before {
  content: "\e922";
}

.icon-tulli-lock-key:before {
  content: "\e99f";
}

.icon-tulli-finance-coupon-scissor:before {
  content: "\e9a0";
}

.icon-tulli-measures:before {
  content: "\e923";
}

.icon-tulli-insurance:before {
  content: "\e9a1";
}

.icon-tulli-message:before {
  content: "\e91c";
}

.icon-tulli-message-read:before {
  content: "\e920";
}

.icon-tulli-message-alert:before {
  content: "\e954";
}

.icon-tulli-message-reply:before {
  content: "\e955";
}

.icon-tulli-message-send:before {
  content: "\e9a2";
}

.icon-tulli-time:before {
  content: "\e956";
}

.icon-tulli-phone:before {
  content: "\e957";
}

.icon-tulli-customer-service:before {
  content: "\e9a3";
}

.icon-tulli-chat:before {
  content: "\e958";
}

.icon-tulli-home:before {
  content: "\e959";
}

.icon-tulli-building-home:before {
  content: "\e9a4";
}

.icon-tulli-building-multistorey:before {
  content: "\e9a5";
}

.icon-tulli-building-storage:before {
  content: "\e9a6";
}

.icon-tulli-building-lighthouse:before {
  content: "\e9a7";
}

.icon-tulli-building-bank:before {
  content: "\e9a8";
}

.icon-tulli-building-fence:before {
  content: "\e9a9";
}

.icon-tulli-surveillance-camera:before {
  content: "\e9aa";
}

.icon-tulli-pin-available:before {
  content: "\e926";
}

.icon-tulli-pin-pinned:before {
  content: "\e927";
}

.icon-tulli-attachment:before {
  content: "\e95a";
}

.icon-tulli-printer:before {
  content: "\e92c";
}

.icon-tulli-device-pc:before {
  content: "\e9ab";
}

.icon-tulli-device-pc-box:before {
  content: "\e9ac";
}

.icon-tulli-device-laptop:before {
  content: "\e9ad";
}

.icon-tulli-device-phone-hand-email:before {
  content: "\e9ae";
}

.icon-tulli-device-phone:before {
  content: "\e9af";
}

.icon-tulli-password:before {
  content: "\e9b0";
}

.icon-tulli-search:before {
  content: "\e92d";
}

.icon-tulli-search-code:before {
  content: "\e943";
}

.icon-tulli-search-change:before {
  content: "\ea44";
}

.icon-tulli-settings:before {
  content: "\e940";
}

.icon-tulli-software-search-question:before {
  content: "\ea45";
}

.icon-tulli-transport-car:before {
  content: "\e9b1";
}

.icon-tulli-transport-truck-logistics:before {
  content: "\e9b2";
}

.icon-tulli-transport-truck:before {
  content: "\e9b3";
}

.icon-tulli-transport-forklift:before {
  content: "\e9b4";
}

.icon-tulli-box-cart:before {
  content: "\e9b5";
}

.icon-tulli-transport-airplane:before {
  content: "\e9b6";
}

.icon-tulli-transport-airplane-ship:before {
  content: "\e9b7";
}

.icon-tulli-transport-train:before {
  content: "\e9b8";
}

.icon-tulli-transport-yacht:before {
  content: "\e9b9";
}

.icon-tulli-transport-ship:before {
  content: "\ea0b";
}

.icon-tulli-file-bankstatement:before {
  content: "\ea46";
}

.icon-tulli-file-time-refresh:before {
  content: "\ea47";
}

.icon-tulli-file-time:before {
  content: "\ea48";
}

.icon-tulli-book-search-question-closed:before {
  content: "\ea49";
}

.icon-tulli-book-search-question-open:before {
  content: "\ea4a";
}

.icon-tulli-file-scale:before {
  content: "\ea4b";
}

.icon-tulli-file-time-lock:before {
  content: "\ea4c";
}

.icon-tulli-transport-ship-regulations:before {
  content: "\ea0c";
}

.icon-tulli-transport-kayak:before {
  content: "\ea0d";
}

.icon-tulli-transport-boat-trailer:before {
  content: "\ea0e";
}

.icon-tulli-globe-traveler:before {
  content: "\e9ba";
}

.icon-tulli-globe-checked:before {
  content: "\e9bb";
}

.icon-tulli-globe-shield:before {
  content: "\e9bc";
}

.icon-tulli-user:before {
  content: "\e941";
}

.icon-tulli-user-send:before {
  content: "\e9bd";
}

.icon-tulli-user-question:before {
  content: "\e9be";
}

.icon-tulli-user-package:before {
  content: "\e9bf";
}

.icon-tulli-user-family:before {
  content: "\ea0f";
}

.icon-tulli-user-female:before {
  content: "\ea10";
}

.icon-tulli-user-activity-hiking:before {
  content: "\ea11";
}

.icon-tulli-accessibility-wheelchair:before {
  content: "\e948";
}

.icon-tulli-user-search:before {
  content: "\e9c0";
}

.icon-tulli-user-photo:before {
  content: "\e9c1";
}

.icon-tulli-maps-map-pin:before {
  content: "\e9c2";
}

.icon-tulli-maps-compass:before {
  content: "\e9c3";
}

.icon-tulli-maps-pin:before {
  content: "\e9c4";
}

.icon-tulli-maps-pins-2:before {
  content: "\e9c5";
}

.icon-tulli-maps-pins-3:before {
  content: "\e9c6";
}

.icon-tulli-speechbubble-question:before {
  content: "\e9c7";
}

.icon-tulli-thumb-up:before {
  content: "\e9c8";
}

.icon-tulli-thumb-down:before {
  content: "\e9c9";
}

.icon-tulli-hand-seed:before {
  content: "\e9ca";
}

.icon-tulli-hand-plant:before {
  content: "\e9cb";
}

.icon-tulli-dog-paw:before {
  content: "\e9cc";
}

.icon-tulli-pdf:before {
  content: "\e925";
}

.icon-tulli-file-xml:before {
  content: "\e949";
}

.icon-tulli-file-xsd:before {
  content: "\e95b";
}

.icon-tulli-file-csv:before {
  content: "\e95c";
}

.icon-tulli-file-xls:before {
  content: "\e95d";
}

.icon-tulli-file-ppt:before {
  content: "\e95e";
}

.icon-tulli-file-txt:before {
  content: "\e95f";
}

.icon-tulli-file-doc:before {
  content: "\e960";
}

.icon-tulli-file:before {
  content: "\e961";
}

.icon-tulli-file-double:before {
  content: "\e9d8";
}

.icon-tulli-file-ok:before {
  content: "\e9d9";
}

.icon-tulli-document-add:before {
  content: "\e900";
}

.icon-tulli-document-remove:before {
  content: "\e914";
}

.icon-tulli-document-refresh:before {
  content: "\ec2e";
}

.icon-tulli-file-import:before {
  content: "\e9da";
}

.icon-tulli-file-export:before {
  content: "\e9db";
}

.icon-tulli-file-denied:before {
  content: "\e9dc";
}

.icon-tulli-file-alert:before {
  content: "\e9dd";
}

.icon-tulli-file-question:before {
  content: "\e9de";
}

.icon-tulli-file-info:before {
  content: "\e9df";
}

.icon-tulli-file-safety:before {
  content: "\e9e0";
}

.icon-tulli-file-locked:before {
  content: "\e9e1";
}

.icon-tulli-file-path:before {
  content: "\e9e2";
}

.icon-tulli-file-globe:before {
  content: "\ea12";
}

.icon-tulli-file-ahvenanmaa:before {
  content: "\e9e3";
}

.icon-tulli-file-graph-pie:before {
  content: "\e9e4";
}

.icon-tulli-file-graph-chart:before {
  content: "\e9e5";
}

.icon-tulli-file-drawer:before {
  content: "\e9e6";
}

.icon-tulli-book-archive:before {
  content: "\e962";
}

.icon-tulli-book-regulations:before {
  content: "\e963";
}

.icon-tulli-book-phone:before {
  content: "\e9e7";
}

.icon-tulli-book-manual:before {
  content: "\e9e8";
}

.icon-tulli-book-download:before {
  content: "\e9e9";
}

.icon-tulli-book-search:before {
  content: "\e9ea";
}

.icon-tulli-book-bookmark:before {
  content: "\e9eb";
}

.icon-tulli-book-open:before {
  content: "\e9ec";
}

.icon-tulli-newspaper:before {
  content: "\e9ed";
}

.icon-tulli-smiley-happy:before {
  content: "\e964";
}

.icon-tulli-smiley-smile:before {
  content: "\e965";
}

.icon-tulli-smiley-smirk:before {
  content: "\e966";
}

.icon-tulli-smiley-indifferent:before {
  content: "\e967";
}

.icon-tulli-smiley-sad:before {
  content: "\e968";
}

.icon-tulli-some-facebook:before {
  content: "\e9ee";
}

.icon-tulli-some-youtube:before {
  content: "\e9ef";
}

.icon-tulli-some-linkedin:before {
  content: "\e9f0";
}

.icon-tulli-some-twitter:before {
  content: "\e9f1";
}

.icon-tulli-some-googleplus:before {
  content: "\e9f2";
}

.icon-tulli-dice:before {
  content: "\e9f3";
}

.icon-tulli-star-circle:before {
  content: "\e9f4";
}

.icon-tulli-product-toy:before {
  content: "\e9f8";
}

.icon-tulli-product-baby-carriage:before {
  content: "\ea13";
}

.icon-tulli-product-plants:before {
  content: "\ea14";
}

.icon-tulli-product-food-seeds:before {
  content: "\ea15";
}

.icon-tulli-product-food-wheat:before {
  content: "\e9f9";
}

.icon-tulli-product-package-present:before {
  content: "\e96d";
}

.icon-tulli-product-shoppingbag-forgery:before {
  content: "\e96e";
}

.icon-tulli-product-shoppingbag-dutyfree:before {
  content: "\e96f";
}

.icon-tulli-product-bone:before {
  content: "\e9fa";
}

.icon-tulli-product-animal-dog:before {
  content: "\e971";
}

.icon-tulli-product-animal-butterfly:before {
  content: "\ea16";
}

.icon-tulli-product-animal-endangered:before {
  content: "\e970";
}

.icon-tulli-product-animal-leather:before {
  content: "\ea17";
}

.icon-tulli-product-animal-fish-fishing:before {
  content: "\ea18";
}

.icon-tulli-product-animal-fish:before {
  content: "\e972";
}

.icon-tulli-product-food:before {
  content: "\ea19";
}

.icon-tulli-product-food-cheese:before {
  content: "\ea1a";
}

.icon-tulli-product-food-chilli:before {
  content: "\ea1b";
}

.icon-tulli-product-food-bottle:before {
  content: "\ea1c";
}

.icon-tulli-product-food-babymilk:before {
  content: "\ea1d";
}

.icon-tulli-product-food-bottle-favorite:before {
  content: "\e9fb";
}

.icon-tulli-product-beauty-perfume:before {
  content: "\e9fc";
}

.icon-tulli-product-spa-aroma:before {
  content: "\e9fd";
}

.icon-tulli-product-medicine-pill:before {
  content: "\ea1e";
}

.icon-tulli-product-medicine-pill-box:before {
  content: "\e9fe";
}

.icon-tulli-product-supplements:before {
  content: "\ea1f";
}

.icon-tulli-product-medicine-drugs:before {
  content: "\ea20";
}

.icon-tulli-product-tobacco:before {
  content: "\e975";
}

.icon-tulli-product-tobacco-cigar:before {
  content: "\e976";
}

.icon-tulli-product-tobacco-nicotinefluid:before {
  content: "\e977";
}

.icon-tulli-product-tobacco-pipe:before {
  content: "\e974";
}

.icon-tulli-product-tobacco-plant:before {
  content: "\e973";
}

.icon-tulli-product-tobacco-chewing:before {
  content: "\e978";
}

.icon-tulli-product-food-alcohol:before {
  content: "\e979";
}

.icon-tulli-product-food-wine-glas:before {
  content: "\e97b";
}

.icon-tulli-product-food-beer-glas:before {
  content: "\e97a";
}

.icon-tulli-product-clothes-blouse:before {
  content: "\ea21";
}

.icon-tulli-product-clothes-tshirt:before {
  content: "\e9ff";
}

.icon-tulli-product-clothes-sweater:before {
  content: "\ea00";
}

.icon-tulli-product-clothes:before {
  content: "\e97c";
}

.icon-tulli-product-clothes-pants:before {
  content: "\ea22";
}

.icon-tulli-product-clothes-blazer:before {
  content: "\ea01";
}

.icon-tulli-product-clothes-dress:before {
  content: "\ea23";
}

.icon-tulli-product-clothes-tie-ribbon:before {
  content: "\ea02";
}

.icon-tulli-product-clothes-hat-cap:before {
  content: "\ea03";
}

.icon-tulli-product-clothes-footwear:before {
  content: "\ea04";
}

.icon-tulli-product-package-bag:before {
  content: "\ea24";
}

.icon-tulli-product-package-briefcase:before {
  content: "\ea25";
}

.icon-tulli-product-package-backpack:before {
  content: "\ea05";
}

.icon-tulli-product-tools-cross:before {
  content: "\ea26";
}

.icon-tulli-product-tools-colourpalette:before {
  content: "\ea27";
}

.icon-tulli-product-sport-tennis:before {
  content: "\ea06";
}

.icon-tulli-product-music-guitar:before {
  content: "\ea28";
}

.icon-tulli-product-money:before {
  content: "\e97e";
}

.icon-tulli-product-valuable-precious:before {
  content: "\ea29";
}

.icon-tulli-product-valuable-ring-diamond:before {
  content: "\ea2a";
}

.icon-tulli-product-forgery:before {
  content: "\ea2b";
}

.icon-tulli-product-chemical:before {
  content: "\ea2c";
}

.icon-tulli-product-gasoline:before {
  content: "\e97d";
}

.icon-tulli-product-outdoor-fire:before {
  content: "\ea2d";
}

.icon-tulli-product-tools-multitool:before {
  content: "\ea2e";
}

.icon-tulli-product-weapon-knife:before {
  content: "\ea2f";
}

.icon-tulli-product-weapon-rifle:before {
  content: "\e97f";
}

.icon-tulli-product-weapon-bullets:before {
  content: "\ea07";
}

.icon-tulli-product-weapon-grenade:before {
  content: "\ea08";
}

.icon-tulli-product-entertainment-fireworks:before {
  content: "\ea09";
}

.icon-tulli-product-tech-camera:before {
  content: "\ea0a";
}

.icon-tulli-product-tech-videocamera:before {
  content: "\ea30";
}

.icon-tulli-product-tech-radio:before {
  content: "\ea31";
}

.icon-tulli-product-tech-laundry:before {
  content: "\ea32";
}

.icon-tulli-product-tech-laser:before {
  content: "\ea33";
}

.icon-tulli-product-tech-lawnmower:before {
  content: "\ea34";
}

.icon-tulli-product-tech:before {
  content: "\e980";
}

.icon-tulli-product-tech-drone:before {
  content: "\ea36";
}

.icon-tulli-product-tech-drone-camera:before {
  content: "\ea37";
}

.icon-tulli-product-transportation-tire-default:before {
  content: "\ea4d";
}

.icon-tulli-product-transportation-tire-summer:before {
  content: "\ea4e";
}

.icon-tulli-product-transportation-tire-snow:before {
  content: "\ea4f";
}

.icon-tulli-sg-designelements:before {
  content: "\e969";
}

.icon-tulli-sg-components:before {
  content: "\e96a";
}

.icon-tulli-sg-patternlibrary:before {
  content: "\e96b";
}

.icon-tulli-sg-other:before {
  content: "\e96c";
}

.icon-tulli-sg-releasenotes:before {
  content: "\e981";
}

.icon-tulli-sg-versions:before {
  content: "\e982";
}

.icon-tulli-logo-icon-background:before {
  content: "\e9f5";
}

.icon-tulli-logo-icon:before {
  content: "\e9f6";
}

.icon-tulli-logo-full:before {
  content: "\e9f7";
}

.icon-tulli-addnew:before {
  content: "\e94f";
}

.icon-tulli-delete-row:before {
  content: "\e950";
}

.icon-tulli-user-change:before {
  content: "\ec34";
}

.icon-tulli-user-id-card:before {
  content: "\ec35";
}

.icon-tulli-star:before {
  content: "\ec3b";
}
.icon-tulli-star-filled:before {
  content: "\ec3c";
}