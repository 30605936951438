/**
 * This file acts as a bundler for all variables/mixins/themes, so they
 * can easily be swapped out without `core.scss` ever having to know.
 */

@import 'forms';
@import 'tables';
@import 'listgroup';

#root {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;

  > div {
    min-height: 100%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
