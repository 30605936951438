@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_grid-framework.scss';

@media print {
  @page {
    margin-left: 0mm;
    margin-right: 0mm;
   }

  html, body {
    font-size: 7pt;
    margin-left: 0;
    margin-right: 0;
  }

  .lead {
    font-size: 8pt;
  }

  .container {
    width: 100% !important;
  }

  // No need to show interactive elements on print view
  .btn, .btn-toolbar, .dropzone {
    display: none;
  }

  // Default resolution in print mode is low, force md-size grids instead of xs.
  @include make-grid(md);

  // Hide root content when modal is open
  body.modal-open #root {
    visibility: hidden;
  }
}
