@import "./bootstrapVariablesBefore.scss";

/**
 * This file contains CSS style customizations overriding Bootstrap
 */
p {
  a:link {
    text-decoration: underline;
  }
}

.no-margin {
  margin: 0;
}

.alert {
  border: 0;
}

.notifications .alert {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-control {
  height: 40px;
  border: 1px solid $tulli-dark-gray-color;

  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    color: $tulli-disabled-color;
    fill: $tulli-disabled-color;
  }

  &::placeholder {
    color: $tulli-input-placeholder;
    opacity: 1;
  }
}

.label {
  border-radius: 0 !important;
  font-weight: inherit;
}

.label-inverse {
  background: white;
  color: $tulli-blue-color;
}

.lead {
  font-size: 1.6rem;
}

.well {
  border: none;
  box-shadow: none;
  background-color: $tulli-header-bg-color;
  padding: $tulli-gutter-width;

  &.has-icon {
    padding-left: 60px + $tulli-gutter-width;
    position: relative;
  }

  &.well-sm {
    padding: $tulli-gutter-width * 0.5;

    &.has-icon {
      padding-left: 60px + ($tulli-gutter-width * 0.5);
    }
  }

  .well-icon {
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    color: white;
    background-color: #bfcadb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tulli-blue {
  color: $tulli-blue-color;
}

.has-success {
  @include form-control-validation(
      $tulli-blue-color,
      $tulli-blue-color,
      $tulli-blue-color
  );
}

.has-warning {
  @include form-control-validation(
      $tulli-warning-color,
      $tulli-warning-color,
      $tulli-warning-bg-color
  );

  .form-control::placeholder {
    color: $tulli-input-placeholder-warning;
  }
}

.has-error {
  .form-control {
    color: $tulli-error-color;
    background-color: $tulli-error-bg-color;

    &::placeholder {
      color: $tulli-input-placeholder-error;
    }
  }
}

.has-warning {
  .form-control {
    color: $tulli-warning-color;
    background-color: $tulli-warning-bg-color;
  }
}

.form-control-static {
  padding-top: $tulli-gutter-width * 0.5;
  padding-bottom: $tulli-gutter-width * 0.5;
  line-height: $tulli-gutter-width;
}

a:hover {
  text-decoration: none;
}

.navbar {
  border: none;
}

.modal-header {
  background: $tulli-blue-color;
  padding-left: 10px;

  &,
  a:link,
  a:hover,
  .btn-link,
  .btn-link:hover {
    color: white;
  }

  @media (min-width: $screen-sm-min) {
    & {
      text-align: center;
    }
  }

  .shoulder-button {
    position: absolute;
    margin-bottom: 0px;
    top: 5px;
  }

  .shoulder-button__left {
    @extend .shoulder-button;
    left: 5px;
  }

  .shoulder-button__right {
    @extend .shoulder-button;
    right: 5px;
  }
}

.modal-footer {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: $tulli-gutter-width;
  text-align: left;
  margin: 0 20px;
  border-top: 3px solid $tulli-yellow;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $tulli-gutter-width;

  button {
    margin: 0;
    white-space: pre-wrap;
  }

  .custom-footer-buttons {
    display: inline;
    width: 100%
  }
}

.modal-footer::before, .modal-footer::after {
  display: none;
}

@media (max-width: $screen-xs) {
  .modal-footer {
    button {
      width: 100%;
    }

    .btn + .btn {
      margin: 0;
    }
  }
}

.page-content,
.modal {
  .btn:not(.btn-link) {
    padding-left: $tulli-gutter-width * 2;
    padding-right: $tulli-gutter-width * 2;
  }
}

.modal-body {
  background-color: #fafafa;

  & > h3 {
    margin-top: 0;
    margin-bottom: $tulli-gutter-width;
  }
}

.modal-dialog.has-icon {
  .modal-body {
    padding-left: $tulli-gutter-width * 3;

    .modal-icon {
      line-height: inherit;
      font-size: inherit;
      position: absolute;
      left: $tulli-gutter-width;
    }
  }
}

.modal-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    position: inherit !important;
  }
}

.modal-title h1, .modal-title h2 {
  color: white;
  font-size: 2.2rem;
  margin: 0;
  padding: 0;
  line-height: inherit;
  text-align: center;
}

.panel {
  border: 0px;
  position: relative;
  box-shadow: none;

  .panel-body {
    .panel-footer {
      margin: $tulli-gutter-width 0 0 0;
      background: none;
      padding: 0;
      border: none;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.confirmationView, &.headersView, &.summaryButtons {
        justify-content: flex-end;
      }

      // Creates the flex space between this button and the next button
      &.confirmationView {
        .printDeclaration {
          margin-right: auto;
          margin-left: 20px;

          @media (max-width: $screen-xs-max) {
            display: block;
            margin: 20px auto 0;
            width: 100%;
          }
        }
      }

      &.confirmationView, &.rowsView {
        .backButton {
          @media (max-width: $screen-xs-max) {
            display: block;
            margin: 20px auto 0;
            width: 100%;
          }
        }
      }

      &.summaryButtons {
        .printDeclaration {
          margin-left: auto;
          margin-right: 5px;

          @media (max-width: $screen-xs-max) {
            display: block;
            margin: 20px auto 0;
            width: 100%;
          }

        }
      }

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
      }

      .btn {
        margin-bottom: $tulli-gutter-width * 0.5;

        &#submitDeclaration {
          order: 5;
        }

        @media (max-width: $screen-xs-max) {
          &:first-of-type {
            margin: 0 auto;
          }

          display: block;
          margin: 20px auto 0;
          width: 100%;

          &#deleteDraft, &#invalidateDeclaration, &.nextButton, &.printDeclaration, &#submitDeclaration {
            margin-top: 20px;
          }
        }

        &#deleteApplication {
          margin-left: auto;
        }

        // Align the lone single next button right
        &#nextButton {
          margin-left: auto;
        }
      }

      &.with-border {
        padding: 20px 0 0 0;
        border-top: 3px solid $tulli-yellow;

        & + .with-border {
          padding: 0;
          border-top: none;
        }
      }
    }
  }

  .panel-indent {
    padding-left: $tulli-gutter-width * 0.5;
    padding-right: $tulli-gutter-width * 0.5;
    padding-bottom: $tulli-gutter-width * 0.5;
  }

  .panel-help {
    & > * {
      margin-left: $tulli-gutter-width * 0.5;
      margin-right: $tulli-gutter-width * 0.5;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: 1px solid $tulli-mid-gray-color;
    padding: $tulli-gutter-width $tulli-gutter-width*0.5;
    font-size: 1.4rem;
    margin-bottom: 0;

    .panel-help-text {
      flex-grow: 2;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .panel-help-toggle {
      white-space: nowrap;
      align-self: flex-start;
      text-decoration: none;
    }
  }

  .panel-help-toggle {
    font-size: 1.6rem;
  }

  & > .panel-help-toggle {
    white-space: nowrap;
    position: absolute;
    right: 30px;
    top: 60px;
    z-index: 10;
    text-decoration: none;
  }

  .panel-heading-icon {
    font-family: "Tulli Icons";
    display: inline-block;
    margin-right: 16px;
    font-size: 16px;
  }

  .panel-heading-extras {
    display: inline-block;
    margin-left: $tulli-gutter-width * 2;
    font-weight: normal;
    font-size: 1.4rem;
  }

  .panel-heading {
    border: 0;
    background: $tulli-blue-color;
    border-left: 10px solid white;
    line-height: 2.4rem;
    font-weight: bold;
    font-size: 1.6rem;

    a,
    a:link,
    a:hover,
    .btn-link {
      color: white;
      text-decoration: none;
    }

    .panel-title {
      display: inline-block;
    }

    .label {
      line-height: 2.4rem;
      padding-top: 0;
      padding-bottom: 0;
      display: inline-block;
      font-size: 1.2rem;
      margin-left: $tulli-gutter-width * 0.5;
      margin-right: $tulli-gutter-width * 0.5;
    }

    .small {
      font-size: 1.4rem;
      font-weight: normal;
    }
  }

  &.active .panel-heading {
    border-left-color: #f2a900;
  }

  &.panel-primary > .panel-heading {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
  }

  &:not(.panel-primary) > .panel-heading {
    display: inline-block;

    .panel-title {
      vertical-align: bottom;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 6px;
      margin-left: 10px;
      content: "";
      display: block;
      border-bottom: 2px solid $tulli-blue-color;
    }
  }
}

// TODO: move non-overriding extras to separate file

.panel-navigation {
  position: relative;
  min-height: 200px;

  h3:not(.h4) {
    margin-top: $tulli-gutter-width * 0.5;
    margin-bottom: $tulli-gutter-width;
  }
}

.absolute-centered {
  position: absolute;
  margin-top: -1em;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

table {
  tr.active {
    td,
    a {
      color: white;
    }
  }

  th {
    color: $tulli-disabled-color;
    font-weight: normal;
  }
}

.btn {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity: 1;
    color: #bbb;
    background-color: #ddd;
    border-color: #bbb;
  }
}

.btn:not(.btn-link):not(.btn-sm):not(.btn-compact):not(.navbar-btn):not(.action-button) {
  border-width: 2px;
  line-height: 1.75;
  padding-left: $tulli-gutter-width;
  padding-right: $tulli-gutter-width;
}

.btn-sm {
  font-size: 1.4rem;
  line-height: 1.6;
}

.btn-toolbar .btn {
  margin-bottom: $line-height-computed;
}

.btn-row {
  padding: 2px 5px 0 5px;
  line-height: $tulli-gutter-width;
  border-width: 0px;
  background-color: transparent;
  min-width: 36px;
  height: 36px;

  &:focus,
  &:hover {
    background-color: $tulli-blue-color;
    color: $tulli-bg-color;
  }
}

.nav-pills {
  margin-bottom: $tulli-gutter-width;

  li {
    a {
      cursor: pointer;
    }

    span {
      cursor: default;
    }

    a,
    span {
      background-color: #bfcadb;
      border-left: 10px solid white;
      min-height: 40px;
      line-height: 1.8rem;
      min-height: 40px;
      padding: 4px 8px;
      display: flex;
      align-items: flex-end;
    }

    &.active a,
    &.active span,
    a:hover,
    a:focus {
      border-left-color: $tulli-btn-warning-color;
      background-color: $tulli-blue-color;
      color: white;
    }

    a:focus {
      -webkit-box-shadow: $tulli-btn-focus-shadow;
      box-shadow: $tulli-btn-focus-shadow;
    }
  }
}

.nav-stacked {
  & > li + li {
    margin-top: 10px;
  }
}

@media (max-width: $screen-sm-max) {
  .nav-stacked.horizontal-on-sm {
    li {
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
    }

    & > li + li {
      margin-top: 0px;
    }
  }
}

.nav-tabs {
  & > li {
    margin-left: 1px;
    font-weight: 700;

    @media (max-width: $screen-xs-max) {
      float: none;
    }

    &:not(.active) > a {
      padding-bottom: 7px;
    }

    & > a {
      @media (max-width: $screen-xs-max) {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      &,
      &:active {
        border-color: transparent;
        background: $tulli-inactive-tab-color;
        padding-left: $tulli-gutter-width * 2;
        padding-right: $tulli-gutter-width * 2;
      }

      &:hover,
      &:focus {
        background: $tulli-hover-tab-color;
      }

      &:focus {
        -webkit-box-shadow: $tulli-btn-focus-shadow;
        box-shadow: $tulli-btn-focus-shadow;
      }
    }
  }

  & > li:first-child {
    margin-left: 10px;

    @media (max-width: $screen-xs-max) {
      margin-left: 1px;
    }
  }

  & > li.active {
    & > a {
      &,
      &:active,
      &:hover,
      &:focus {
        background: $tulli-light-gray-color;
        font-weight: 700;
        border-style: solid;
        border-top-width: 4px;
        border-top-color: $tulli-blue-color;
      }
    }
  }
}

.tab-content {
  background: $tulli-white-color;
  padding: $tulli-gutter-width * 0.5;
}

table {
  .sort-container {
    .sort-order {
      opacity: 0.5;
      display: inline-block;
      vertical-align: super;
      font-size: 0.8em;
      margin-top: -0.4em;
    }

    .sort {
      display: inline-block;
      font-family: "Tulli Icons";
      font-size: 1rem;
      cursor: pointer;
      margin-left: 10px;

      &.sort-asc:before {
        content: "\E113";
      }

      &.sort-desc:before {
        content: "\E114";
      }

      &.sort-none:before {
        content: "\E150";
      }

      &.sort-none {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.btn-toolbar {
  margin-left: 0;
}

.panel-footer {
  // Remove the clearfix hack that prevents the toolbar to work as a flex container.
  &:after {
    content: none;
  }

  &:before {
    content: none;
  }
}

.btn-toolbar {
  > .btn,
  > .btn-group,
  > .input-group {
    margin-left: $tulli-gutter-width * 0.5;
    margin-right: $tulli-gutter-width * 0.5;
  }
}

.form-horizontal .control-label {
  text-align: left;
  display: block;
}

@media (min-width: $screen-sm-min) {
  .form-horizontal .control-label {
    padding-top: $tulli-gutter-width * 0.5;
  }
}

@media (max-width: $screen-xs-max) {
  .px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

.jumbotron {
  .tulli-icon {
    color: $tulli-blue-color;
  }
}

.tooltip {
  display: block;
  opacity: 1;

  .tooltip-content {
    max-width: $tooltip-max-width;
    padding: 5px 10px;
    color: $tooltip-color;
    text-align: center;
    background-color: $tooltip-bg;
  }

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &[data-popper-placement^="top"] {
    margin-top: -3px;
    padding: $tooltip-arrow-width 0;

    .arrow {
      bottom: 0;
      left: 50%;
      margin-left: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
      border-top-color: $tooltip-arrow-color;
    }
  }

  &[data-popper-placement^="right"] {
    margin-left: 3px;
    padding: 0 $tooltip-arrow-width;

    .arrow {
      top: 50%;
      left: 0;
      margin-top: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
      border-right-color: $tooltip-arrow-color;
    }
  }

  &[data-popper-placement^="bottom"] {
    margin-top: 3px;
    padding: $tooltip-arrow-width 0;

    .arrow {
      top: 0;
      left: 50%;
      margin-left: -$tooltip-arrow-width;
      border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
      border-bottom-color: $tooltip-arrow-color;
    }
  }

  &[data-popper-placement^="left"] {
    margin-left: -3px;
    padding: 0 $tooltip-arrow-width;

    .arrow {
      top: 50%;
      right: 0;
      margin-top: -$tooltip-arrow-width;
      border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.alert {
  .close {
    color: inherit;
    opacity: 1;
  }
}

.popover {
  display: block;
  // z-index: 10000;

  .close {
    margin: -10px -10px 10px 10px;
    font-size: 1.6rem;
  }

  @media (min-width: $screen-xxs-min) {
    &.large {
      min-width: 400px;
    }
  }

  .popover-content {
    padding: $tulli-gutter-width;
    overflow: auto; // add scrollbars for long popovers

    @media (max-width: $screen-xxs-min) {
      max-height: 80vh;
    }


    p {
      max-width: none;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &[data-popper-placement^="top"] {
    margin-bottom: $tulli-gutter-width * 0.5;

    > .arrow {
      left: calc(50% - $popover-arrow-outer-width);
      bottom: -$popover-arrow-outer-width;
      border-bottom-width: 0;
      border-top-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        bottom: 1px;
        margin-left: -$popover-arrow-width;
        border-bottom-width: 0;
        border-top-color: $popover-arrow-color;
      }
    }
  }

  &[data-popper-placement^="right"] {
    margin-left: $tulli-gutter-width * 0.5;

    > .arrow {
      top: calc(50% - $popover-arrow-outer-width);
      left: -$popover-arrow-outer-width;
      border-left-width: 0;
      border-right-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        left: 1px;
        bottom: -$popover-arrow-width;
        border-left-width: 0;
        border-right-color: $popover-arrow-color;
      }
    }
  }

  &[data-popper-placement^="bottom"] {
    margin-top: $tulli-gutter-width * 0.5;

    > .arrow {
      left: calc(50% - $popover-arrow-outer-width);
      top: -$popover-arrow-outer-width;
      border-top-width: 0;
      border-bottom-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        top: 1px;
        margin-left: -$popover-arrow-width;
        border-top-width: 0;
        border-bottom-color: $popover-arrow-color;
      }
    }
  }

  &[data-popper-placement^="left"] {
    margin-right: $tulli-gutter-width * 0.5;

    > .arrow {
      top: calc(50% - $popover-arrow-outer-width);
      right: -$popover-arrow-outer-width;
      border-right-width: 0;
      border-left-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        right: 1px;
        border-right-width: 0;
        border-left-color: $popover-arrow-color;
        bottom: -$popover-arrow-width;
      }
    }
  }
}

.messageThreadHeading {
  .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.print {
  .container,
  .container-fluid {
    padding-left: $tulli-gutter-width;
    padding-right: $tulli-gutter-width;

    .row {
      margin-left: -$tulli-gutter-width;
      margin-right: -$tulli-gutter-width;
    }
  }

  .panel-indent {
    padding-left: 0;
    padding-right: 0;
  }
}

mark,
.mark {
  background-color: transparent;
  font-weight: bold;
  color: $tulli-blue-color;
  padding-left: 0;
  padding-right: 0;
}

input::-ms-clear {
  display: none;
}

.radio-inline {
  margin-right: 10px;
  margin-left: 0px;
}

.radio-inline + .radio-inline {
  margin-left: 0px;
}

.accessibility-links-container {
  position: relative;
  float: left;
  z-index: 9999;
}

.accessibility-links-container h1,
.accessibility-links-container h2,
.accessibility-links-container h3,
.accessibility-links-container p {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ccc;
  line-height: 2em;
  z-index: 1;
  display: block;
  color: #333;
}

.accessibility-links-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accessibility-links-container ul li {
  display: block;
  float: left;
}

.accessibility-links-container ul li a {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ccc;
  line-height: 2em;
  z-index: 1;
  display: block;
  color: #333;
  font-weight: normal;
  text-align: center;
  background-image: none;
  border: 2px solid #00205b;
  white-space: nowrap;
  padding: 8px 20px;
}

.accessibility-links-container ul li a:focus {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  clip: auto;
}

.accessibility-supportive-text {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  background: #fff;
  border: 1px solid #ccc;
  line-height: 2em;
  z-index: 1;
  display: block;
  color: #333;
}

.headerbar-authSelectionContainer {
  position: relative;
}

.notification-warning input.form-control,
.notification-warning select.form-control {
  background-color: $tulli-warning-bg-color;
}
