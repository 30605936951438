@import 'variables';

/**
 * This file is loaded before Bootstrap variables are defined.
 *
 * Bootstrap can depend on these variables as defaults.
 */

$font-family-sans-serif: $tulli-font-family-sans-serif;
$brand-primary: $tulli-blue-color;
$brand-success: $tulli-success-color;
$brand-info: $tulli-info-color;
$text-color: $tulli-black-color;

// Override bootstrap variables here
$border-radius-base:  0;
$border-radius-large: 0;
$border-radius-small: 0;
$body-bg: $tulli-bg-color;

// Panels (used widely as content containers)
$panel-body-padding:                        $tulli-gutter-width*0.5;
$panel-primary-border:                      transparent;
$panel-bg:                                  $tulli-light-gray-color;
$panel-heading-padding:                     8px 10px;

$alert-padding:                             $tulli-gutter-width;
$modal-inner-padding:                       $tulli-gutter-width;
$grid-gutter-width:                         $tulli-gutter-width;

// Navbars
$navbar-height:                             40px;
$navbar-inverse-color:                      #CCC;
$navbar-inverse-bg:                         $tulli-top-bar-color;
$navbar-inverse-border:                     transparent;
$navbar-inverse-link-color:                 $navbar-inverse-color;
$navbar-inverse-link-hover-color:           $tulli-white-color;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $tulli-white-color;
$navbar-inverse-link-active-bg:             transparent;
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;
$navbar-inverse-toggle-hover-bg:            #333;
$navbar-inverse-toggle-icon-bar-bg:         $tulli-white-color;
$navbar-inverse-toggle-border-color:        #333;

// Tabs
$nav-tabs-border-color:                     transparent;
$nav-tabs-link-hover-border-color:          transparent;
$nav-tabs-active-link-hover-bg:             transparent;
// $nav-tabs-active-link-hover-color:          transparent;
$nav-tabs-active-link-hover-border-color:   transparent;

$font-size-base:                            $tulli-base-font-size;
$font-size-h1:                              $tulli-h1-font-size;
$font-size-h2:                              $tulli-h2-font-size;
$font-size-h3:                              $tulli-h3-font-size;
$font-size-h4:                              $tulli-h4-font-size;
$font-size-h5:                              $tulli-h5-font-size;
$font-size-h6:                              $tulli-h6-font-size;
$headings-color:                            $tulli-blue-color;

$well-bg:                                   transparent;
$well-border:                               $tulli-light-gray-color;

$table-bg-accent:                           $tulli-list-even-color;
$table-bg-hover:                            $tulli-list-hover-color;
$table-bg-active:                           $tulli-blue-color;

$btn-default-color:                         $tulli-blue-color;
$btn-default-bg:                            $tulli-white-color;
$btn-default-border:                        $tulli-blue-color;

$btn-primary-color:                         $tulli-white-color;
$btn-primary-bg:                            $tulli-blue-color;
$btn-primary-border:                        $tulli-blue-color;

$btn-warning-color:                         $tulli-white-color;
$btn-warning-bg:                            $tulli-btn-warning-color;
$btn-warning-border:                        #d99800;

$modal-title-padding:                       5px;
$modal-title-line-height:                   30px;
$modal-footer-border-color:                 transparent;
$modal-header-border-color:                 transparent;
$modal-backdrop-opacity:                    0.7;
$modal-content-bg:                          $tulli-light-gray-color;

$state-info-bg:                             $tulli-white-color;
$state-warning-text:                        $tulli-warning-color;
$state-warning-bg:                          $tulli-warning-bg-color;
$state-danger-text:                         $tulli-error-color;
$state-danger-bg:                           $tulli-error-bg-color;
$state-danger-border:                       darken(adjust-hue($state-danger-bg, -10), 10%);

$input-color:                               $tulli-black-color;
$input-border:                              $tulli-mid-gray-color;
$input-border-focus:                        $tulli-input-focus-color;
$input-bg-disabled:                         $tulli-disabled-bg-color;
$gray-light:                                $tulli-disabled-color;

$jumbotron-color:                           $tulli-black-color;
$jumbotron-bg:                              $tulli-white-color;
$jumbotron-padding:                         $tulli-gutter-width * 2;

$popover-max-width:                         500px;
$popover-bg:                                $tulli-bg-color;
$popover-arrow-color:                       $tulli-bg-color;
$popover-border-color:                      #B8BFCC;
$popover-arrow-outer-color:                 $popover-border-color;

$screen-xxs-min:                            430px;

