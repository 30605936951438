@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus, .focused {
    border-color: $color;
    outline: 0;
    box-shadow: 0 0 0 1px $color;
  }
  &:focus {
    -webkit-box-shadow: $tulli-field-focus-shadow;
    box-shadow: $tulli-field-focus-shadow;
  }
}

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    &:focus, .focused {
      border-color: $border-color;
      box-shadow: 0 0 0 1px $border-color;
    }
    &:focus {
      -webkit-box-shadow: $tulli-field-focus-shadow;
      box-shadow: $tulli-field-focus-shadow;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 25%);
  }
  &:focus {
    -webkit-box-shadow: $tulli-btn-focus-shadow;
    box-shadow: $tulli-btn-focus-shadow;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 6%);
        border-color: darken($border, 6%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
    &:focus {
      -webkit-box-shadow: $tulli-btn-focus-shadow;
      box-shadow: $tulli-btn-focus-shadow;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  /*
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }
  */

  .badge {
    color: $background;
    background-color: $color;
  }
}

div[role="button"]:focus {
  -webkit-box-shadow: $tulli-btn-focus-shadow;
  box-shadow: $tulli-btn-focus-shadow;
}
